import { FormGroup, FormArray } from "@angular/forms";
import * as moment from "moment";
import { RoomConfigurationItem } from "../home/search-box/categories/hotels/roomConfigurationItem";
import { roomSearchConfigurationItem } from "../home/search-box/categories/hotels/roomSearchConfigurationItem";


import { environment } from "environments/environment";

// tslint:disable-next-line:max-line-length
export function searchPayload(categoria: string, dateRange: any, form: FormGroup, destiny: string, origin: string = "", extras: any = {}, marketId?:number, nationality?: string, agencyId?:number) {
  interface payload { [key: string]: any; }
  let  _payload: payload = {};

  //console.log('llegando dateRange', dateRange);
 // console.log('extras',  extras.returnDestinations );


  _payload = {
    providersId: null,
    categoriesId: [1],
    others: {
      maxRequestTimeOnSeconds: environment.maxRequestTimeOnSeconds,
      minNumResults: environment.minNumResults,
      maxNumResultsPerAdapter: environment.maxNumResultsPerAdapter,
    },
  };

  switch (categoria) {
    case 'HOTELS':
        _payload.categoriesId = [1];
        _payload.origin = origin,
        _payload.destination = destiny,
        _payload.city = 'Miami',
        _payload.priceFrom = 0,
        _payload.priceTo = 20000,
        // tslint:disable-next-line:max-line-length
        _payload.arrivalDate = dateRange.start.$d ? moment(dateRange.start.$d.toString().split(",")[0]).format("YYYY-MM-DD") : dateRange.start,
        // tslint:disable-next-line:max-line-length
        _payload.departureDate = dateRange.end.$d ? moment(dateRange.end.$d.toString().split(",")[0]).format("YYYY-MM-DD") : dateRange.end,
        _payload.qtyProduct = +form.value['qtyProduct'],
        _payload.qtyPax = +form.value['qtyAdults'],
        _payload.qtyPax = _getAdultsChildrenQty(categoria, 'qtyAdults', form);
        _payload.nationality = nationality;
        _payload.agencyId = agencyId;
        _payload.page = 1;
        _payload.per_page = 10;
        _payload.others.hotels = {
            roomConfiguration: _getRoomDist(form),
      };
      break;
      case "TOURS":
      _payload.categoriesId = [6];
      _payload.origin = origin,
      _payload.destination = destiny,
      _payload.agencyId = agencyId;
      _payload.nationality = "US";
      _payload.city = 'Miami',
      _payload.priceFrom = 0,
      _payload.priceTo = 20000,
      // tslint:disable-next-line:max-line-length
      _payload.arrivalDate = dateRange.start.$d ? moment(dateRange.start.$d.toString().split(",")[0]).format("YYYY-MM-DD") : dateRange.start,
      // tslint:disable-next-line:max-line-length
      _payload.departureDate = dateRange.end.$d ? moment(dateRange.end.$d.toString().split(",")[0]).format("YYYY-MM-DD") : dateRange.end,

      _payload.others.tours = {
        adults: +form.value["qtyAdults"],
        children: form.value["childsage"].map((obj) => obj.age) || [null],
      };
      break;

    case "INSURANCE":
      _payload.categoriesId = [10];
      _payload.qtyPax = _getAdultsChildrenQty(categoria, "adultsage", form).length;
      _payload.providersId = ["6da576a0-5843-11e8-9ff6-9954e05b5694", "618824e0-6aa0-11e8-b5cf-99969a0182dc"];
      _payload.others.insurance = {
        annual: false,
        adults: _getAdultsChildrenQty(categoria, "adultsage", form),
        children: _getAdultsChildrenQty(categoria, "childsage", form),
      };
      break;
    case "PTHEME":
      _payload.categoriesId = [4];
      _payload.others.tickets = {
        adults: parseInt(form.value["qtyAdults"]),
        children: form.value["childsage"].map((obj) => obj.age) || [null],
      };
      break;
    case "DISNEY":
      let adults = 0;
      form.value["room"].forEach((room) => {
        adults += Number.parseInt(room["qtyAdults"]);
      });
      const childs = [];

      form.value["room"].forEach((room) => {
        room["childsage"].forEach((age) => {
          childs.push(age["age"]);
        });
      });
      _payload.qtyPax = adults;
      _payload.qtyAdults = adults;
      _payload.providersId = ["c38caa90-f019-11e7-b791-2702c92c19de"];
      _payload.categoriesId = [12];
      _payload.disneyCategory = ["ALL"];
      _payload.hotelId = 0;
      _payload.searchId = null;
      _payload.rooms = {
        qtyPax: adults,
        adults: adults,
        childs: childs,
      };
      _payload.others = {
        minNumResults: 5,
        orderColumn: "title",
        orderType: "ASC",
        roomIntelligence: false,
        serialized: true,
      };
      break;
    case "RENT-A-CAR":
      _payload.providersId = [];
      _payload.categoriesId = [2],
      _payload.agencyId = agencyId;
      _payload.arrivalDate = dateRange.start.$d ? moment(dateRange.start.$d.toString().split(",")[0]).format("YYYY-MM-DD") : dateRange.start,
      _payload.departureDate = dateRange.end.$d ? moment(dateRange.end.$d.toString().split(",")[0]).format("YYYY-MM-DD") : dateRange.end,
      _payload.nationality = "US";
      _payload.others.carRental = {
        ageDriver:  form.value["age"],
        lang: 'ESP',
        CountryOfResidence: 'ES',
        pickupLocation: {
          origin: origin,
          // tslint:disable-next-line:max-line-length
          pickupDate: dateRange.start.$d ? moment(dateRange.start.$d.toString().split(",")[0]).format("YYYY-MM-DD") : dateRange.start,
          pickupTime: form.value['hourOrigin'] + ':00'
        },
        dropoffLocation: {
          destination: extras.returnDestinations ? origin : destiny,
          // tslint:disable-next-line:max-line-length
          dropoffDate: dateRange.end.$d ? moment(dateRange.end.$d.toString().split(",")[0]).format("YYYY-MM-DD") : dateRange.end,
          dropoffTime: form.value['hourDestination'] + ':00'
        }
      };
      break;
    case "PACKAGES":
      _payload.categoriesId = [7];
      _payload.qtyPax = _getAdultsChildrenQty(categoria, "qtyAdults", form);
      break;
    default:
      break;
  }
  return _payload;
}

export function _getAdultsChildrenQty(categoria: string, key: string, form: FormGroup) {
  let qtyPax: any;
  let qty = 0;
  switch (categoria) {
    case "HOTELS":
      key === "qtyAdults"
        ? form.value["room"].map((obj) => obj.qtyAdults).forEach((element) => (qty += +element))
        : form.value["room"].map((obj) => obj.qtyChilds).forEach((element) => (qty += +element));
      qtyPax = qty;
      break;
    case "PACKAGES":
      key === "qtyAdults"
        ? form.value["room"].map((obj) => obj.qtyAdults).forEach((element) => (qty += +element))
        : form.value["room"].map((obj) => obj.qtyChilds).forEach((element) => (qty += +element));
      qtyPax = qty;
      break;

    default:
      const ages = [];
      if (key === "adultsage") {
        let cont = 0;
        for (let i = 0; i < form.value.room.length; i++) {
          const adultsage = form.value.room[i].adultsage;
          for (let j = 0; j < adultsage.length; j++) {
            ages.push(adultsage[j].age);
            cont++;
          }
        }
      } else {
        let cont = 0;
        for (let i = 0; i < form.value.room.length; i++) {
          const childsage = form.value.room[i].childsage;
          for (let j = 0; j < childsage.length; j++) {
            ages.push(childsage[j].age);
            cont++;
          }
        }
      }
      qtyPax = ages;
      break;
  }

  return qtyPax;
}

export function _getRoomDist(form?: FormGroup) {
  const roomDist = [];
  const rooms = form.value.room;
  for (let i = 0; i < rooms.length; i++) {
    const people = [];
    const dist: roomSearchConfigurationItem = {
      adults: 0,
      children: []
    };
    const adults = rooms[i].qtyAdults;
    const children = rooms[i].childsage;
    for (let j = 0; j < children.length; j++) {
      people.push(children[j].age);
    }
    dist.adults = adults;
    dist.children = people;
    roomDist.push(dist);
  }
  return roomDist;
}

export function getPayload(item: any) {
  const itemPayload = item.payloadUpdate ? item.payloadUpdate.products[0] : item.payload ? item.payload : null;
  let payload;
  switch (item.categoryName) {
    case "INSURANCE":
      payload = {
        products: [
          {
            providerId: item.providerId,
            nameAdapter: item.nameAdapter,
            categoryId: itemPayload.categoryId,
            productId: item.idProduct,
            productName: item.title,
            origin: itemPayload.origin ? itemPayload.origin : null,
            destination: itemPayload.destination,
            priceFrom: itemPayload.priceFrom,
            priceTo: itemPayload.priceTo,
            arrivalDate: item.arrivalDate,
            departureDate: item.departureDate,
            others: getPaxListWithFormat(item.payload.others, item.categoryName, item),
          },
        ],
      };
      break;
    case "PTHEMES":
      payload = {
        products: [
          {
            providerId: item.providerId,
            nameAdapter: itemPayload.nameAdapter,
            categoryId: itemPayload.categoryId,
            productId: itemPayload.productId,
            productName: itemPayload.productName,
            destination: itemPayload.destination,
            priceFrom: itemPayload.priceFrom,
            priceTo: itemPayload.priceTo,
            arrivalDate: item.arrivalDate,
            departureDate: item.departureDate,
            others: getPaxListWithFormat(itemPayload.others, item.categoryName, item),
          },
        ],
      };
      break;
    default:
      payload = getPayloadWithStandardFormat(item, itemPayload);
      break;
  }

  console.log("getPayload", payload);
  return payload;
}
export function getPayloadWithStandardFormat(item: any, itemPayload: any): any {
  return {
    products: [
      {
        providerId: item.providerId,
        nameAdapter: itemPayload.nameAdapter,
        categoryId: itemPayload.categoryId,
        productId: itemPayload.productId,
        productName: itemPayload.productName,
        origin: itemPayload.origin ? itemPayload.origin : null,
        destination: itemPayload.destination,
        priceFrom: itemPayload.priceFrom,
        priceTo: itemPayload.priceTo,
        arrivalDate: item.arrivalDate,
        departureDate: item.departureDate,
        qtyProduct: itemPayload.qtyProduct,
        qtyPax: itemPayload.qtyPax ? itemPayload.qtyPax : item.maxPax,
        others: getPaxListWithFormat(itemPayload.others, item.categoryName, item),
      },
    ],
  };
}
export function getPaxListWithFormat(others: any, category: string, item: any): any {
  let paxList;

  switch (category) {
    case "HOTELS":
      let child = 0;
      const ages = [];
      const agesAdult = [];
      const roomDist = [];
      others.hotels.roomConfiguration.forEach((room, index) => {
        child += room.children.length;
        const _room = [];
        for (let i = 0; i < Number.parseInt(room.adults); i++) {
          agesAdult.push(21);
          _room.push(21);
        }
        room.children.forEach((age) => {
          ages.push(Number.parseInt(age));
          _room.push(Number.parseInt(age));
        });
        roomDist.push(_room);
      });
      paxList = {
        child: child,
        ages: { int: ages },
        agesAdult: { int: agesAdult },
        roomDist: roomDist,
      };
      break;
    case "PTHEMES":
      paxList = {
        maxRequestTimeOnSeconds: 20,
        minNumResults: 3,
        maxNumResultsPerAdapter: "",
        tickets: {
          adults: item.paxAdults,
          children: others.ages.int,
        },
      };
      break;
    case "INSURANCE":
      paxList = {
        serialized: false,
        maxRequestTimeOnSeconds: others.maxRequestTimeOnSeconds,
        minNumResults: others.minNumResults,
        insurance: {
          adults: others.insurance.adults,
          children: others.insurance.children,
        },
        orderColumn: "title",
        orderType: "ASC",
      };
      break;
    case "TOURS":
      paxList = others;
      break;
    case "RENT-A-CAR":
      paxList = {
        carRental: {
          pickupTime: others.carRental.pickupTime,
          dropoffTime: others.carRental.dropoffTime,
          pickupLocation: {
            id: others.carRental.pickupLocation.id,
            type: others.carRental.pickupLocation.type,
            address: others.carRental.pickupLocation.address,
            providerId: others.carRental.pickupLocation.providerId,
            nameAdapter: others.carRental.pickupLocation.nameAdapter,
          },
          dropoffLocation: {
            id: others.carRental.dropoffLocation.id,
            type: others.carRental.dropoffLocation.type,
            address: others.carRental.dropoffLocation.address,
            providerId: others.carRental.dropoffLocation.providerId,
            nameAdapter: others.carRental.dropoffLocation.nameAdapter,
          },
        },
      };
      break;
    default:
      break;
  }

  return paxList;
}
