import { Directive, Input, Renderer2, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { loadAgency, setAgency } from 'app/core/profiles/profiles.actions';
import { selectAgency } from 'app/core/profiles/profiles.selectors';
import { AppState } from 'app/core/profiles/storeProfile';
import { UserService } from 'app/pages/com.usblick.common/auth/user.service';
import { Observable, Subscription } from 'rxjs';
import { setting } from '../../../../setting';
import { LoadingSplashService } from './loading-splash.service';

@Directive({
  selector: '[usbLoadingSplash]'
})
export class LoadingSplashDirective implements OnChanges {
  @Input() appLoading: boolean;
  @Input() fullscreen: boolean = false;

  public comparisonRouteImagenLogo: any;
  public agencyImagen: string = '';
  public routeUpdate: string = '';

  private loaderElement: HTMLElement;
  private subscription: Subscription;

  constructor(
              private el: ElementRef,
              private renderer: Renderer2,
              public userService: UserService,
              private loadingSplash: LoadingSplashService
            ) {
              const agency = JSON.parse(localStorage.getItem(setting.name));
              this.routeUpdate = setting.routeUploadFile;
              this.agencyImagen = `${this.routeUpdate}${agency?.logo_path_two}`;
              this.createLoader();
              }

              ngOnChanges(changes: SimpleChanges): void {
                console.log(changes)
                // Verifica si el valor de appLoading ha cambiado
                if (changes?.appLoading) {
                  const currentValue = changes.appLoading.currentValue;

                  if (currentValue) {
                    console.log('entre')
                    this.showLoader();
                  } else {
                    console.log('entre')
                    this.hideLoader();
                  }
                }
              }

  private images: string[] = [
    'assets/img/icon-loader/avion.gif',
    'assets/img/icon-loader/hotel.gif',
    'assets/img/icon-loader/viaje-en-autobus.gif'
  ];
  private currentImageIndex = 0;

  private createLoader() {
    this.loaderElement = this.renderer.createElement('div');
    this.renderer.addClass(this.loaderElement, 'loading-screen');

    const img = this.renderer.createElement('img');
    this.renderer.addClass(img, 'img-loader');
    this.renderer.appendChild(this.loaderElement, img);

    this.changeImage(img); // Cambiar la imagen inmediatamente al inicio

    // Configurar el intervalo para cambiar la imagen cada segundo
    setInterval(() => {
      this.changeImage(img);
    }, 1500);

    const spinner = this.renderer.createElement('div');
    this.renderer.addClass(spinner, 'spinner');

    const bounce1 = this.renderer.createElement('div');
    this.renderer.addClass(bounce1, 'bounce1');

    const bounce2 = this.renderer.createElement('div');
    this.renderer.addClass(bounce2, 'bounce2');

    const bounce3 = this.renderer.createElement('div');
    this.renderer.addClass(bounce3, 'bounce3');

    this.renderer.appendChild(spinner, bounce1);
    this.renderer.appendChild(spinner, bounce2);
    this.renderer.appendChild(spinner, bounce3);

    this.renderer.appendChild(this.loaderElement, spinner);
    this.addStyles();
  }

  private changeImage(img: HTMLImageElement) {
    img.src = this.images[this.currentImageIndex];
    this.currentImageIndex = (this.currentImageIndex + 1) % this.images.length;
  }


  private addStyles() {
    const style = this.renderer.createElement('style');
    const css = `
.loading-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    pointer-events: none;
    opacity: 1;
    visibility: visible;
    transition: opacity 400ms cubic-bezier(0.4, 0, 0.2, 1);
}


.img-loader {
    width: 120px;
    max-width: 120px;
}

 .spinner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
    width: 56px;
}

 .spinner > div {
    width: 12px;
    height: 12px;
    background-color: #111827;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: fuse-bouncedelay 1s infinite ease-in-out both;
    animation: fuse-bouncedelay 1s infinite ease-in-out both;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

 .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes fuse-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0)
    }
    40% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes fuse-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}
    `;
    const text = this.renderer.createText(css);
    this.renderer.appendChild(style, text);
    this.renderer.appendChild(document.head, style);
  }

  private showLoader() {
    if (this.fullscreen) {
      this.renderer.setStyle(this.loaderElement, 'position', 'fixed');
      this.renderer.appendChild(document.body, this.loaderElement);
    } else {
      this.renderer.setStyle(this.loaderElement, 'position', 'absolute');
      this.renderer.appendChild(this.el.nativeElement, this.loaderElement);
      this.renderer.setStyle(this.el.nativeElement, 'position', 'relative');
    }
  }

  private hideLoader() {
    if (this.loaderElement.parentNode) {
      this.renderer.removeChild(this.loaderElement.parentNode, this.loaderElement);
    }
  }

//   get agencyImagen(): string {
//     let agencyImagen = this.userService._imagen_logotype;
//     if (agencyImagen === this.comparisonRouteImagenLogo || agencyImagen === '') {
//         return agencyImagen =  'assets/img/sin-logo.png';
//     } else {
//         return agencyImagen;
//     }
// }

}
