export const setting= {
  name: "Moebius",
  production: false,
  c_domain: '.fusionpax.com',
  apiBTIUrl: 'https://auth-api.usblick.com/api/',
  appUrl: 'https://login-staging.fusionpax.com',
  authApiUrl : 'https://auth-api.usblick.com/api/',
  appBookingBackoffice: 'https://booking-bo-staging.fusionpax.com/',
  appBookingUrl: 'https://booking-staging.fusionpax.com/',
  apiBookingUrl: 'https://booking-api-staging.fusionpax.com/api/v1/',
  apiOctopusUrl: 'https://octopus-apis.com/api',
  routeUploadFile: 'https://auth-api.usblick.com/storage/app/public/',
  octopusUrl: 'https://octopus-apis.com/api',
  apiStorageBooking: 'https://booking-api-staging.fusionpax.com/storage/app/public/',
  clientIdPaypal:"EHd6g3jaGD82NlUcn09stPzBNEgVYQaSUge13EY8wMcbnXHXS_gAkGuEATvJ9qsCjRMCffvwzFVDDEH6", 
  client_id:  'QA7kxgtk2eKrJDwK',
  client_secret: 'mL6SNux7pD8p8FoQRneYz0BP9NI9AP1519938479',
  defauldTimeBooking: 10,
  ApiUser : "MOEV254135",
  ApiKey  : "7245M841O01EV25",
  app_names: {
  	booking_backoffice: 'backoffice',
  	zoco: 'zoco',
  	booking: 'booking',
  	abaco: 'abaco',
  	chat: 'chat',
  	octopus: 'octopus'
  },

  ActivatedGuard: true
};
