<div *ngIf="results && results.length > 0" class="search-results col-12">
  <!-- Replicar este div para cada ubicacion -->
  <div class="header row mt-2 d-flex align-items-center justify-content-between" *ngIf="origin=='MULTIDESTINATION'">
    <div class="col-2 pl-1 text-left">
      <h6>{{category}} Destiny:
        <span>{{payload.destination}}</span>
      </h6>
      <!-- Colocar destino en el span -->
    </div>

    <div class="col-4 center-block" *ngIf="payload">
      <usb-item-selected [destino]="results.destination" (collapse)="closeCollapse($event)" [categoryName]="categoryName"
        [payload]="payload"></usb-item-selected>
    </div>

    <div class="col-4 center-block justify-content-center" *ngIf="payload" style="font-size:12px">
      <usb-pax-list-modal *ngIf="_actualItinerary" [tabSelected]="tabSelected" (emitReload)="reloadSearch($event)"
        [city]="payload.destination" [_payload]="payload" [invoke]="3" [actualItinerary]="_actualItinerary"
        (emitReSearch)="_emitReSearch($event)">
      </usb-pax-list-modal>
    </div>

    <div class="col-1 center-block">
      <div *ngIf="tabSelected=='TRANSPORTS' && ori!== undefined ">
        <button class="btn btn-sm btn-usblick-pink justify-content-center my-1" (click)="ModalRooms.show()">
          <i class="icon-edit icon fa fa-search"></i>
        </button>
        <usb-modals-search bsModal #ModalRooms="bs-modal" class="modal  zindex fade" tabindex="-1" role="dialog" aria-hidden="true"
          [modalRoomRef]="ModalRooms" [_ori]="ori" [origin]="tabSelected" [cityIataCode]="payload.destination"
          (emitReload)="reloadSearch($event)" (isLoading)="_onLoading($event)">
        </usb-modals-search>
      </div>
    </div>
    <div class="col-1 text-center">
      <button class="btn-acordeon" (click)="toggleCollapse()"><i class="fa"
          [ngClass]="{'fa-chevron-up': contentCollapsed,'fa-chevron-down': !contentCollapsed}"></i>
      </button>
    </div>
  </div>

  <div class="header row d-flex align-items-center justify-content-between" *ngIf="origin!='MULTIDESTINATION'"  [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_2}">
    <div class="d-flex flex-column col-6">
      <h6 *ngIf=" origin == 'HOTELS'" class="head-title m-0 mb-1" [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}">Hoteles en {{cityNameForm?.destination?.city_name}}</h6>
      <h6 *ngIf=" origin == 'RENT-A-CAR'" class="head-title m-0 mb-1" [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_1}">Rent a car en {{cityNameForm?.origin?.city_name}}</h6>
      <h6 *ngIf=" origin == 'TOURS'" class="head-title m-0 mb-1" [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_1}">Tours en {{cityNameForm.destination.city_name}}</h6>
      <!-- payload.others.carRental.dropoffLocation.destination-->
      <div class="d-flex" *ngIf="origin == 'HOTELS' ">
         <span class="head-subtitle" [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}"> IN: {{payload.arrivalDate | date:'dd-MMM-YY'}}  </span>
         <span class="head-subtitle ml-2" [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}">| OUT: {{payload.departureDate | date:'dd-MMM-YY'}} </span>
       </div>

       <div class="d-flex" *ngIf="origin == 'TOURS' ">
         <span class="head-subtitle" [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}"> IN: {{payload.arrivalDate | date:'dd-MMM-YY'}}  </span>
         <span class="head-subtitle ml-2" [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}">| OUT: {{payload.departureDate | date:'dd-MMM-YY'}} </span>
       </div>

       <div class="d-flex" *ngIf="origin == 'RENT-A-CAR' ">
         <span class="head-subtitle" [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}"> IN: {{payload.others.carRental.pickupLocation.pickupDate | date:'dd-MMM-YY'}}  </span>
         <span class="head-subtitle ml-2" [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}">| OUT: {{payload.others.carRental.dropoffLocation.dropoffDate | date:'dd-MMM-YY'}} </span>
       </div>
    </div>



    <div class="d-flex" *ngIf="payload" style="font-size:12px">
      <usb-pax-list-modal *ngIf="_actualItinerary" (emitReload)="reloadSearch($event)" [city]="payload.destination" [_payload]="payload"
        [invoke]="3" [actualItinerary]="_actualItinerary" (emitReSearch)="_emitReSearch($event)">
      </usb-pax-list-modal>
      <!-- [__paxAdults]="_paxAdults" [__paxChildren]="_paxChildren" -->
    </div>



  </div>

  <!-- FILTROS -->
  <div #filterContainer class="d-flex justify-content-between align-items-center filters" style="margin-bottom: 35px !important">
    <div class="filter">
      <div class="content-filter d-flex flex-row w-100" style="padding: 3px !important">
        <div class="d-flex align-items-center filter-btn" (click)="toggleFilters()">
          <i class="i-layer"></i>
          <hr class="vertical-hr-light-dark mx-3 my-0">
          Filtros
          <span *ngIf="countFilterApply > 0 " class="countFilterApply"> {{ countFilterApply }}</span>
        </div>
        <!-- <div class="clear-filter" style="margin-right: 50px;"> -->
          <button class="font-title-group text-center" (click)="clearFilter()"
                  style="font-family: 'Poppins';width: 230px !important; height: 25px !important;padding: 0px 15px !important;border-radius: 18px; margin-right: 5px;color: white; background-color: black"
                  > Limpiar
          filtros
         </button>
        <!-- </div> -->
      </div>


      <div class="select-filters" *ngIf="showFilters && results && results.length > 0">
        <div class="d-flex justify-content-between align-items-center" style="height: 40px !important; padding: 0px 20px !important;">
          Filtrado por:
          <button type="button" class="close" aria-label="Close" (click)="toggleFilters()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <hr class="m-0">
        <!-- filter price -->
        <div class="group-filter">
          <div class="form-group" *ngIf="filterLowPrice > 0 && filterHighPrice > 0">

            <label for="formControlRange" class="font-title-group text-left w-100 pt-3 pb-3" style="color: #55637d !important"> Rango de precio por día</label>
            <!--  <div class="d-flex align-items-center justify-content-between">
              <span class="d-block font-range">${{filterLowPrice | number: '1.2-2'}}</span>
              <span class="d-block font-range">${{filterHighPrice | number: '1.2-2'}}</span>
            </div>-->
            <ngx-slider (userChange)="applyFilter($event,'','', '')" id="sliderPrice" [(value)]="filterLowPrice"
              [(highValue)]="filterHighPrice" [options]="optionsSliderPrice"> </ngx-slider>
            <span class="d-block w-100 font-total-range text-right"> {{totalProduct}} productos</span>
          </div>
        </div>
        <hr class="m-0">
        <!-- filter Tipos -->

        <div class="group-filter">
       <!--  <usb-search-field (textEmitter)="textEmitter($event)" class="row"></usb-search-field>  -->

       <label class="font-title-group text-left w-100 pb-2 pt-3 " style="color: #55637d !important">Nombre</label>
            <div id="search" class="col-xg-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="row">
                  <div class="col-xg-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <input type="text" [(ngModel)]="text" class="form-control" placeholder="Buscar" (ngModelChange)="textEmitter($event)">
                      <button type="submit" class="btn" title="Search"><i class="fa fa-search"></i></button>
                  </div>
              </div>
          </div>

          <label class="font-title-group text-left w-100 pb-2 pt-3 " style="color: #55637d !important" *ngIf="origin == 'HOTELS'">Categoría</label>
          <div style="padding-bottom: 1em !important;" *ngIf="origin == 'HOTELS'">
            <img
              class="ml-2 icon-image"
              *ngFor="let starImage of stars(); let index = index"
              [style.filter]="index > selectedStars ? 'grayscale(100%)' : 'none'"
              [style.color]="index === 0 ? '#d3d3d3' : (index <= selectedStars ? 'none' : '#d3d3d3')"
              (click)="filterStar(index)"
              [src]="starImage"
              alt="star icon"
            >
          </div>

        <label class="font-title-group text-left w-100 pt-3 " style="color: #55637d !important" *ngIf="origin == 'HOTELES'">Amenities</label>
          <div style="height: 500px !important; overflow-y: scroll;" *ngIf="origin == 'HOTELS'">
            <ng-container *ngFor="let amenities of amenitiesFilter">
              <div class="d-flex align-center mb-2">
                <input class="mb-1" type="checkbox" [value]="amenities.name" (click)="filterAmenities($event.target.checked, $event.target.value)">
                <p class="mb-1 ml-1" style="font-size: 13px;color: #55637d !important">{{amenities.name}}</p>
              </div>
            </ng-container>
          </div>



        <!-- <label class="font-title-group text-left w-100 pb-2 pt-3 ">Categoría</label>
          <div class="w-100 item-selects" *ngIf="filtersRentacar.brand.brandArray.length > 0">
            <div *ngFor="let value of filtersRentacar.brand.brandArray; let i = index">
              <div *ngIf="filtersRentacar.brand.brandCant[i] != 0">
                <div class="d-flex">
                  <div class="row m-0 p-0" style="cursor: pointer; width: 100%;line-height: 30px;">
                    <div class="col-2">
                      <input type="checkbox" [disabled]=" filtersRentacar.brand.brandCant[i] ==0 " class="example-margin" color="primary"
                        [(ngModel)]="filtersRentacar.brand.checkedArray[i]" (change)="applyFilter($event, value, 'name', i)">
                    </div>
                    <div class="col-8" style="text-align: start;">
                      <span class="label-mat-checkbox">{{ capitalizeFirstLetter(value) }}</span>
                    </div>
                    <div class="col-2">
                      <span class="label-mat-checkbox"> {{ filtersRentacar.brand.brandCant[i] }} </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->


        <!-- <label class="font-title-group text-left w-100 pb-2 pt-3 ">Compañia</label>
          <div class="w-100 item-selects" *ngIf="filtersRentacar.brand.brandArray.length > 0">
            <div *ngFor="let value of filtersRentacar.brand.brandArray; let i = index">
              <div *ngIf="filtersRentacar.brand.brandCant[i] != 0">
                <div class="d-flex">
                  <div class="row m-0 p-0" style="cursor: pointer; width: 100%;line-height: 30px;">
                    <div class="col-2">
                      <input type="checkbox" [disabled]=" filtersRentacar.brand.brandCant[i] ==0 " class="example-margin" color="primary"
                        [(ngModel)]="filtersRentacar.brand.checkedArray[i]" (change)="applyFilter($event, value, 'name', i)">
                    </div>
                    <div class="col-8" style="text-align: start;">
                      <span class="label-mat-checkbox">{{ capitalizeFirstLetter(value) }}</span>
                    </div>
                    <div class="col-2">
                      <span class="label-mat-checkbox"> {{ filtersRentacar.brand.brandCant[i] }} </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <!-- <label class="font-title-group text-left w-100 pb-2 pt-3">Categoría del Vehiculo</label>
          <div class="w-100 item-selects" *ngIf="filtersRentacar.category.categoryArray.length > 0">
            <div *ngFor="let value of filtersRentacar.category.categoryArray; let i = index">
              <div *ngIf="filtersRentacar.category.categoryCant[i] != 0">
                 <mat-checkbox class="example-margin" color="primary" [(ngModel)]="filtersRentacar.category.checkedArray[i]" (change)="applyFilter($event, value, 'category', i)">
                <div class="d-flex justify-content-between align-items-center w-100">
                  <span class="label-mat-checkbox">{{ capitalizeFirstLetter(value) }}</span>
                  <span class="countItemFilter"> {{ filtersRentacar.category.categoryCant[i] }} </span>
                </div>
              </mat-checkbox>

                <div class="d-flex">
                  <div class="row m-0 p-0" style="cursor: pointer; width: 100%;line-height: 30px;">
                    <div class="col-2">
                      <input type="checkbox" [disabled]="filtersRentacar.category.categoryCant[i] ==0 " class="example-margin"
                        color="primary" [(ngModel)]="filtersRentacar.category.checkedArray[i]"
                        (change)="applyFilter($event, value, 'category', i)">
                    </div>
                    <div class="col-8" style="text-align: start;">
                      <span class="label-mat-checkbox">{{ capitalizeFirstLetter(value) }}</span>
                    </div>
                    <div class="col-2">
                      <span class="label-mat-checkbox"> {{ filtersRentacar.category.categoryCant[i] }} </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <!-- <label class="font-title-group text-left w-100 pb-2 pt-3">Tipo de Vehiculo</label>
          <div class="w-100 item-selects" *ngIf="filtersRentacar.type.typeArray.length > 0">
            <div *ngFor="let value of filtersRentacar.type.typeArray; let i = index">
              <div *ngIf="filtersRentacar.type.typeCant[i] != 0">
                 <mat-checkbox class="example-margin" color="primary" [(ngModel)]="filtersRentacar.type.checkedArray[i]"  (change)="applyFilter($event, value, 'type', i)">
                <div class="d-flex justify-content-between align-items-center w-100">
                  <span class="label-mat-checkbox">{{  capitalizeFirstLetter(value.toString().split(')', 1)[0].replace("(", "")) }}</span>
                  <span class="countItemFilter"> {{ filtersRentacar.type.typeCant[i] }} </span>
                </div>
              </mat-checkbox>

                <div class="d-flex">
                  <div class="row m-0 p-0" style="cursor: pointer; width: 100%;line-height: 30px;">
                    <div class="col-2">
                      <input type="checkbox" [disabled]=" filtersRentacar.type.typeCant[i] ==0 " class="example-margin" color="primary"
                        [(ngModel)]="filtersRentacar.type.checkedArray[i]" (change)="applyFilter($event, value, 'type', i)">
                    </div>
                    <div class="col-8" style="text-align: start;">
                      <span class="label-mat-checkbox">{{ capitalizeFirstLetter(value) }}</span>
                    </div>
                    <div class="col-2">
                      <span class="label-mat-checkbox"> {{ filtersRentacar.type.typeCant[i] }} </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
        <hr class="m-0">
        <hr class="m-0">
        <hr class="m-0">

      </div>
    </div>

    <div class="results-count d-flex align-items-center justify-content-center">
      <!-- <a class="loader text-center justify-content-center" *ngIf="results.length==0">
          <i class="fa fa-spinner fa-spin"></i>  Buscando...
        </a> -->
      <div *ngIf=" origin == 'HOTELS'"> {{ resultTotal }} Hoteles Disponibles </div>
      <div *ngIf=" origin == 'RENT-A-CAR'"> {{ results?.length }} Rent a car Disponibles </div>
      <div *ngIf=" origin == 'TOURS'"> {{ results?.length }} Tours Disponibles </div>

    </div>

    <div class="order-by d-flex align-items-center justify-content-center">
      Ordenar por
      <hr class="vertical-hr-light-dark ml-1 mr-2 my-0">
      <div class="select-order d-flex align-items-center" (click)="showOrder=!showOrder">
        {{orderOptions[orderPriceby]}}
        <i class="i-arrow-down ml-2"></i>
      </div>
      <div class="select-options" *ngIf="showOrder">
        <div class="d-flex justify-content-between align-items-center" style="height: 40px !important; padding: 0px 20px !important;">
          Ordernar por:
          <button type="button" class="close" aria-label="Close" (click)="showOrder=!showOrder">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <hr class="m-0">
        <p class="m-0 cursor-pointer" (click)="orderPriceby='priceLow'; filterResults(); showOrder=!showOrder">{{orderOptions['priceLow']}}
        </p>
        <p class="m-0 cursor-pointer" (click)="orderPriceby='priceHigh'; filterResults(); showOrder=!showOrder">
          {{orderOptions['priceHigh']}}</p>
        <!--  <p class="m-0 cursor-pointer" (click)="filterResults(); orderPriceby='masRelevantes';showOrder=!showOrder">{{orderOptions['masRelevantes']}}</p> -->
      </div>
    </div>
    <div *ngIf="!viewMapsResult &&  origin != 'RENT-A-CAR'" class="maps-results cursor-pointer"
      (click)="viewMapsResult=!viewMapsResult; mapMarker();">
      <span style="background-color: black !important;">VER EN MAPA</span>
    </div>
    <div *ngIf="viewMapsResult" class="list-results cursor-pointer"
      (click)="viewMapsResult=!viewMapsResult; mapMarker(); _emitViewMap(false)">
      <span>VER LISTA</span>
    </div>

  </div>

  <p *ngIf="booleanHideIcons" style="text-align: center;">No se encontraron datos, para los parámetros suministrados!</p>
  <!-- <span *ngIf="booleanHideIcons" style="text-align: center;" (click)="templateSend(templateRooms)">Nueva Busqueda</span> -->

  <div class="progress" style="height: 20px;">
    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 75%;">
      Cargando...
    </div>
  </div>


  <div class="content" [class.collapsed]="contentCollapsed">
    <!-- INICIO DE RESULTADOS MULTIDESTINO -->
    <div *ngIf="origin=='MULTIDESTINATION'">
      <div *ngIf="(tabSelected==='HOTELS')">
        <div *ngIf="(resultPaged?.length > 0) else noResultsFound">
          <div *ngFor="let item of resultPaged; let i = index">
            <usb-hotel-result [arrayResults]="results" [result]="item" [payload]="payload" [index]="i" [form]="results.form"
              [origin]="tabSelected" ></usb-hotel-result>
          </div>
        </div>
      </div>



      <!-- RESULTADOS DE TRANSPORTS MULTIDESTINATION  -->
      <div *ngIf="tabSelected==='TRANSPORTS'">
        <div *ngIf="(resultPaged?.length > 0) else noResultsFound">
          <div *ngFor="let item of resultPaged; let i = index">
            <usb-transport-result [arrayResults]="results" [result]="item" [payload]="payload" [index]="i" [form]="results.form"
              [origin]="tabSelected">
            </usb-transport-result>
            <!-- [__pax]="_pax"
             [__payloadForm]="_payloadForm"
            -->
          </div>
        </div>
      </div>
      <!-- RESULTADOS DE TOURS  -->
      <div *ngIf="tabSelected==='TOURS'">
        <div *ngIf="(resultPaged?.length > 0) else noResultsFound">
          <div *ngFor="let item of resultPaged; let i = index">
            <usb-activities-result [arrayResults]="results" [result]="item" [payload]="payload" [index]="i" [form]="results.form"
              [origin]="tabSelected"></usb-activities-result>
          </div>
        </div>
      </div>
      <!-- SUMMARY ITINERARY  -->
      <div *ngIf="tabSelected==='SUMMARY'">
        <usb-itinerary-summary></usb-itinerary-summary>
      </div>
    </div>
    <!-- FIN DE RESULTADOS MULTIDESTINO -->

    <!-- INICIO DE RESULTADOS INDIVIDUALES -->
    <!-- RESULTADOS DE HOTELES | paginate: { itemsPerPage: 20, currentPage: page } -->

    <div *ngIf="origin==='HOTELS' && !viewMapsResult && resultPaged.length>0" style="margin-top: 15px !important;">
      <div *ngFor="let resultHotel of resultPaged  ">
        <ng-container *ngFor="let item of resultHotel | paginate: { itemsPerPage: 10, currentPage: page }; let i = index">
          <ng-container *ngIf="item.additionalInfo">
            <usb-hotel-result [arrayResults]="results" [result]="item" [payload]="payload" [index]="i" [form]="results.form" [origin]="origin">
            </usb-hotel-result>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <!-- RESULTADOS DE TOURS  -->
    <div *ngIf="origin==='TOURS' && !viewMapsResult && resultPaged.length>0" style="margin-top: 15px !important;">
      <div *ngFor="let item of resultPaged | paginate: { itemsPerPage: 20, currentPage: page }; let i = index ">
        <usb-activities-result [arrayResults]="results" [result]="item" [payload]="payload" [index]="i" [form]="results.form"
          [origin]="origin"></usb-activities-result>
      </div>
    </div>
    <!-- RESULTADOS DE CRUISES  -->
    <div *ngIf="origin==='CRUISES' && !viewMapsResult && resultPaged.length>0" style="margin-top: 15px !important;">
      <div *ngFor="let item of resultPaged | paginate: { itemsPerPage: 20, currentPage: page }; let i = index ">
        <usb-cruises-result [arrayResults]="results" [result]="item" [payload]="payload" [index]="i" [form]="results.form"
          [origin]="origin"></usb-cruises-result>
      </div>
    </div>
    <!-- RESULTADOS DE RENT A CAR  -->
    <div *ngIf="origin==='RENT-A-CAR'">
      <div *ngFor="let item of resultPaged | paginate: { itemsPerPage: 20, currentPage: page }; let i = index ">
        <usb-rent-a-car-result  [arrayResults]="resultsAux" [result]="item" [payload]="payload" [index]="i" [form]="resultsAux.form"
          [origin]="origin"></usb-rent-a-car-result>
      </div>
    </div>
    <!-- [ngClass]="{ 'hidden': !resultReload, 'visible': resultReload, 'opacityTransition': true }" -->
    <!-- RESULTADOS DE TRANSPORTS PARA EL HOME  -->
    <div *ngIf="origin==='TRANSPORTS' && !viewMapsResult && resultPaged.length>0" style="margin-top: 15px !important;">
      <div *ngFor="let item of resultPaged | paginate: { itemsPerPage: 20, currentPage: page }; let i = index ">
        <usb-transport-result [arrayResults]="results" [result]="item" [payload]="payload" [index]="i" [form]="results.form"
          [origin]="origin">
        </usb-transport-result>

        <!-- [__pax]="_pax"
        [__payloadForm]="_payloadForm"
        -->
      </div>
    </div>
    <!-- RESULTADOS DE PACKAGES  -->
    <div *ngIf="origin==='PACKAGES'">
      <div *ngFor="let item of resultPaged | paginate: { itemsPerPage: 20, currentPage: page }; let i = index ">
        <usb-packages-result [arrayResults]="results" [result]="item" [payload]="payload" [index]="i" [form]="results.form"
          [origin]="origin"></usb-packages-result>
      </div>
    </div>
    <!-- RESULTADOS DE DISNEY  -->
    <div *ngIf="origin==='DISNEY' && !viewMapsResult && resultPaged.length>0" style="margin-top: 15px !important;">
      <div *ngFor="let item of resultPaged | paginate: { itemsPerPage: 20, currentPage: page }; let i = index ">
        <usb-disney-result [arrayResults]="results" [result]="item" [payload]="payload" [index]="i" [form]="results.form" [origin]="origin">
        </usb-disney-result>
      </div>
    </div>
    <!-- RESULTADOS DE PTHEMES  -->
    <div *ngIf="origin==='PTHEMES' && !viewMapsResult && resultPaged.length>0" style="margin-top: 15px !important;">
      <div *ngFor="let item of resultPaged | paginate: { itemsPerPage: 20, currentPage: page }; let i = index ">
        <usb-p-themes-result [arrayResults]="results" [result]="item" [payload]="payload" [index]="i" [form]="results.form"
          [origin]="origin" [imageItem]="getUrlImagePark(item.title)"></usb-p-themes-result>
      </div>
    </div>
    <!-- RESULTADOS DE INSURANCE  -->
    <div *ngIf="origin==='INSURANCE'&& !viewMapsResult && resultPaged.length>0" style="margin-top: 15px !important;">
      <div *ngFor="let item of resultPaged | paginate: { itemsPerPage: 20, currentPage: page }; let i = index ">
        <usb-insurance-result [arrayResults]="results" [result]="item" [payload]="payload" [index]="i" [form]="results.form"
          [origin]="origin" [disbaledCompare]="disbaledCompare" (toCompare)="toCompare()" (addToCompare)="addToCompare($event)"
          [_itemToCompare]="itemToCompare"></usb-insurance-result>
      </div>
    </div>




    <!-- MAPS RESULTS -->
    <div style="margin-top: 15px;"
      [ngClass]="{'d-block': (origin==='HOTELS' && viewMapsResult), 'd-none': !(origin==='HOTELS' && viewMapsResult)}">
      <div class="d-flex">
        <div class="d-flex flex-column" *ngIf="viewCardResult">
          <div class="list-hotels-maps">

            <div *ngFor="let item of resultPaged; let i = index ">
              <usb-hotel-result [arrayResults]="results" [result]="item" [payload]="payload" [index]="i" [form]="results.form"
                [origin]="origin" [mapView]="true" (coordinatesClicked)="moveMapToCoordinates($event)">
              </usb-hotel-result>
            </div>


            <!-- <div *ngFor="let item of resultPaged; let i = index ">
              <usb-tours-result [arrayResults]="results" [result]="item" [payload]="payload" [index]="i" [form]="results.form"
                [origin]="origin" [mapView]="true">
              </usb-tours-result>
            </div> -->

          </div>
        </div>
        <div class="d-flex content-map w-100">
          <div class="d-flex float-button-map">
            <button class="btn btn-show-results d-flex align-items-center justify-content-center mr-2"
              (click)="viewCardResult=!viewCardResult">
              <img class="mr-2" style="width:24px; height:24px"
                src="{{viewCardResult? '/assets/img/app/icons-result/chevron_left.svg' : '/assets/img/app/icons-result/chevron_right.svg'}}">
              Lista
              <!-- {{viewCardResult? 'icon' : '> Lista'}} -->
            </button>
            <div class="d-flex align-items-center bg-white"
              style="border-radius: 8px; height: 40px !important; padding: 0px 15px !important;">
              <img class="mr-2" style="width:24px; height:24px;" src="/assets/img/app/icons-result/checkbox.svg"
                alt="/assets/img/app/icons-result/checkbox.svg">
              <p>Buscar mientras me desplazo por el mapa</p>
            </div>
          </div>
          <div #map style="min-height: 450px; margin: 15px 0px 0px 0px !important; width: 100%;"></div>
        </div>
      </div>
    </div>

    <!-- FIN DE RESULTADOS INDIVIDUALES -->
    <!--pagination-controls [ngClass]="{'pag': _total === 9,'pagFull': _total >9}" (pageChange)="changePage($event)" id="pagination" autoHide="true"></pagination-controls-->

    <!--<div *ngIf="tabSelected==='TRANSPORTS' && emitLoad && resultPaged?.length > 0">
      <div class="text-center col-12 w-100">
        <button *ngIf="(_items === 5)" id="load-more" (click)="more()" class="btn btn-ligth-gray w-50">More
          <i class="{{loaded?'fa fa-spinner fa-spin fa-sm':''}}"></i>
        </button>
      </div>
    </div> -->

    <!-- <div *ngIf="tabSelected!='TRANSPORTS'">
      <div class="text-center col-12 w-100">
        <button *ngIf="(_items === 5)" id="load-more" (click)="more()" class="btn btn-ligth-gray w-50">More
          <i class="{{loaded?'fa fa-spinner fa-spin fa-sm':''}}"></i>
        </button>
      </div>
    </div>  -->

    <!-- pagination
    <div class="d-flex justify-content-center align-items-center nav-pagination" *ngIf="!viewMapsResult">
      <nav aria-label="Page navigation">
        <ul class="pagination justify-content-center m-0">
          <li class="pagination-item">
            <a class="pagination-link" (click)="moreInfo('-')" tabindex="-1"> < </a>
          </li>
          <li class="pagination-item active"><a class="pagination-link" href="#">1</a></li>
          <li class="pagination-item"><a class="pagination-link" href="#">2</a></li>
          <li class="pagination-item"><a class="pagination-link" href="#">3</a></li>
          <li class="pagination-item"><p class="pagination-link m-0">...</p></li>
          <li class="pagination-item"><a class="pagination-link" href="#">10</a></li>
          <li class="pagination-item">
            <a class="pagination-link" (click)="moreInfo('+')"> > </a>
          </li>
        </ul>
      </nav>
    </div>  -->

    <!-- paginacion   (pageChange)="page = $event" -->
    <div class="d-flex justify-content-center align-items-center nav-pagination" *ngIf="!viewMapsResult && !booleanHideIcons">
      <pagination-controls *ngIf="resultTotal >= 10" class="pt-3" (pageChange)="onPageChange($event)">
      </pagination-controls>
    </div>

    <usb-compare-modal bsModal #modalCompare="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true"
      [form]="results.form" [arrayResults]="results" [origin]="origin" [payload]="payload" [itemToCompare]="itemToCompare"
      (modalCompareClose)="modalCompareClose($event)"></usb-compare-modal>
  </div>

  <ng-template #noResultsFound>
    <div class="col-12 pt-10 text-center">
      <h4>No results found for this search.</h4>
    </div>
  </ng-template>
</div>
