import { Component, OnInit, ViewChild, Input, ViewContainerRef, Output, EventEmitter } from "@angular/core";

import { ToastrService } from "ngx-toastr";
import { FormGroup } from "@angular/forms";
import { ItineraryService } from "../../itinerary.service";
import { RoomConfigurationItem } from "../../../home/search-box/categories/hotels/roomConfigurationItem";
import { CookieService } from "ngx-cookie";
import { isValidOwener, fillOwnerMessage } from "../../../utils/Functions";
import { UserService } from "../../../pages/com.usblick.common/auth/user.service";
import { MaterialModalConfirmComponent } from "../../material-modal-confirm/material-modal-confirm.component";
import { MatDialog } from "@angular/material/dialog";
import { environment } from "environments/environment";
import { ActivatedRoute, Router, NavigationExtras } from "@angular/router";
import * as e from "cors";
import { forEach } from "lodash";
import { RestService } from "app/shared/rest.service";
import { log } from "console";
import { OwlOptions } from "ngx-owl-carousel-o";

@Component({
  selector: "usb-modal-hotel",
  templateUrl: "./modal-hotel.component.html",
  styleUrls: ["./modal-hotel.component.scss"],
})
export class ModalHotelComponent implements OnInit {
  @Input() productQty: number;
  @Input() hotel: any;
  @Input() results: any;
  @Input() form: any;
  @Input() payloadUpdate?: any;
  @Input() itineraryItem?: any;
  @Input() origin?: any;
  @Input() arrayResults?: any;
  @Input() view_info: any;
  @Input() tabActive?: any;
  @Input() departureDate:any;
  @Output() refModal = new EventEmitter<any>();
  @ViewChild("gmap") gmapElement: any;

  max: number;
  rate:number = 4;
  ratereview = 4.5;
  isReadonly: boolean;
  x = 5;
  y = 2;
  select: any[] = [];
  totalQty = 0;
  single: any[];
  view: any[] = [200, 150];
  disableButton: boolean = true;
  controTabs: boolean = false;
  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = false;
  xAxisLabel = ".";
  showYAxisLabel = true;
  yAxisLabel = "%";
  form_: any;
  nuevoObjeto = [];
  nuevoObjetoAux = [];
  amenitiesRoom:any = [];
  arrServicesExtras: any[] = [];
  otherService: any[] = [];
  currencySelected: string;

  colorScheme = {
    domain: ["#00a680", "#00a680", "#00a680", "#00a680", "#00a680"],
  };

  tab: string = "";
  imgList: any[] = [];
  imgSelect: number = 0;
  _cookieClient: any = undefined;
  step = 0;
  roomsGroup = {};
  roomAdd: any;
  amenitiesHotelInfo = [];
  RoomsValueObs:number = 0;
  lastItemHotel:any[] = [];
  arrHowToGet:any[] = [];
  arrRequirements:any[] = [];
  arrRequirementsCovid:any[] = [];
  arrRequirementsOther:any[] = [];
  arrFacilitiesAndService: any[] = [];
  arrOthersTax: any[] = [];
  arrAccessibility:any[] = [];
  arrFoodAndDrink:any[] = [];
  amenitiesBoolean:boolean = true;
  howToGetBoolean:boolean = true;
  atracttionsBoolean:boolean = true;
  frequentsQBoolean:boolean = true;
  starRate:boolean = true;
  descriptText:string = '';
  descriptComplete:boolean = true;
  toggleDescript:string = '';
  booleanModal:boolean = true;
  continueProgress:boolean = false;
  selectedRooms: any[] = [];
  roomData: any[] = [];
  roomsArr: any[] = [];
  indexRoom: any[] = [];
  roomSelect = [];
  formAuxArr: any[] = [];
  formData: any;

  checkIn:string = '';
  checkOut:string = '';

  customOptionsPhotos: OwlOptions = {
    autoHeight: true,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    navText: ['<img src="../../../../assets/img/app/arrow-izq-slider.png" />', '<img src="../../../../assets/img/app/arrow-der-slider.png" />'],
    items: 1,
    nav: true
    }



  constructor(
    public vcr: ViewContainerRef,
    private _itineraryService: ItineraryService,
    private _cookieService: CookieService,
    public userService: UserService,
    private toastr: ToastrService
  ) {
    this.single = [
      {
        name: "Excelente",
        value: 74,
      },
      {
        name: "Muy Bueno",
        value: 19,
      },
      {
        name: "Regular",
        value: 6,
      },
      {
        name: "Malo",
        value: 1,
      },
      {
        name: "Pésimo",
        value: 0,
      },
    ];
    this._itineraryService.booleanModalActive$.subscribe((res:any) =>{
      console.log('res del booleanmodal ->', res);
      this.booleanModal = res;
      // console.log('booleanModal ->', this.booleanModal);

    })
  }

  ngOnInit() {

    // console.log('hotel modal-hotel', this.hotel.additionalInfo.starRating);
    console.log('amenitiesRoom ->', this.amenitiesRoom);
    console.log('hotel modal-hotel', this.hotel.additionalInfo);
    this.rate = Number(this.hotel.additionalInfo.starRating);

    this.hotel?.additionalInfo?.howtoget?.forEach((element:any) => {
      this.arrHowToGet.push(element);
    });

    this.descriptText = this.hotel.description;


    this.checkIn = this.hotel.additionalInfo.TimeInformation.checkIn;
    this.checkOut = this.hotel.additionalInfo.TimeInformation.checkOut;

    this.formData = JSON.parse(localStorage.getItem("form"));
    // console.log(this.formData)
    // console.log(this.formData.room)
    if(this.formData && Array.isArray(this.formData.room)) {

      this.roomData = this.formData.room;
      // console.log(this.roomData)
    } else {
      this.roomData.push(this.formData.room)
    }
    // console.log('form del localstorage -->', formData);
    // console.log('form del localstorage -->', this.payloadUpdate);

    this.tab = this.tabActive ? this.tabActive : "rooms";

    if (!this.view_info) {
      if (this.hotel !== undefined && this.hotel.additionalInfo !== undefined) {
        if (this.hotel.additionalInfo.rooms !== undefined) {
          this.createSelectsOfRoom(this.hotel.additionalInfo.rooms.length, this.productQty + 1);
        }
        if (this.hotel.additionalInfo.roomsPackage !== undefined) {
          this.createSelectsOfRoom(this.hotel.additionalInfo.roomsPackage.length, this.productQty + 1);
        }
      }
    }
    this.setRoomsGroup();
    // this.loadImages();
    this.getAmenitiesHotelInfo();
    this.getRequirements();
    this.getObservations();
    this.servicesExtras();
    this.booleanGenerals();
    this.toggleDescript = 'Ver Más';
    this.toggleTextComplete();
    this.getAmenitiesRoom();
  }

  selectionChange(rooms: any) {
    //Tuve que cambiar la forma en como accedía, generaba un bug en el select.
    // this.roomData = rooms?.room;
    this.roomData = rooms;
  }

  getAmenitiesHotelInfo() {
    this.amenitiesHotelInfo = this.hotel.additionalInfo.amenities
      .filter(function (amenite: any) {
        return amenite.ame_type === "hotel";
      })
      .map(function (amenite: any) {
        return amenite;
      });

    // console.log("this.getAmenitiesHotelInfo", this.amenitiesHotelInfo);
  }

  getAmenitiesRoom(){
    this.amenitiesRoom = this.hotel.additionalInfo.amenities.filter(
      (amenitie:any) => {
        return amenitie.ame_type === "room";
      }
    )
  }

  setRoomsGroup() {
    let i = 0;
    console.log(this.hotel)
    const roomType = this.hotel.additionalInfo?.rooms;
    // console.log("seleccion de hotel --->", roomType);
    console.log(roomType)
    this.currencySelected = roomType[0]?.room?.pricing?.currency;
    this.nuevoObjeto = roomType.reduce(function (r, a) {
      r[a.room.roomType] = r[a.room.roomType] || [];
      r[a.room.roomType].push(a);
      return r;
    }, Object.create(null));

    // console.log("array nuevoObjeto", this.nuevoObjeto);

    Object.keys(this.nuevoObjeto).map((key) => {
      this.nuevoObjetoAux[i] = this.nuevoObjeto[key];
      console.log(this.nuevoObjetoAux)
      i++;
    });

    // console.log("array nuevoObjetoAux", this.nuevoObjetoAux);
  }

  setStep(index: number) {
    this.step = index;
  }

  cacheReturn() {
    if (localStorage.getItem("others")) {
      // console.log("Se asigno others de cache", localStorage.getItem("others"));
      return JSON.parse(localStorage.getItem("others"));
    } else {
      // tslint:disable-next-line:max-line-length
      return this.arrayResults === undefined
        ? this.itineraryItem.payloadUpdate.products[0].others !== undefined
          ? this.itineraryItem.payloadUpdate.products[0].others
          : ""
        : this.arrayResults.others;
    }
  }

  cacheAdd() {
    if (this.itineraryItem) {
      // tslint:disable-next-line:max-line-length
      // console.log(
      //   "this.itineraryItem.payloadUpdate.products[0].others",
      //   this.itineraryItem.payloadUpdate ? this.itineraryItem.payloadUpdate.products[0].others : "no existe"
      // );
    }

    if (this.itineraryItem) {
      if (!localStorage.getItem("others")) {
        // console.log("Se guardo others");
        localStorage.setItem("others", JSON.stringify(this.itineraryItem.payloadUpdate.products[0].others));
      }
    }
  }

  mapMarker() {
    //se inicializa el mapa con zoom 4
    // var geocoder;
    // var mapa;
    // var mapOptions = {
    //   center: new google.maps.LatLng(28.3256749, -81.51555309999999),
    //   zoom: 4,
    //   mapTypeId: google.maps.MapTypeId.ROADMAP,
    // };

    // //seteamos el maa
    // mapa = new google.maps.Map(this.gmapElement.nativeElement, mapOptions);

    // //buscamos las coordenadas con la direccion dada del hotel
    // geocoder = new google.maps.Geocoder();
    // var title = this.hotel.title;
    // geocoder.geocode({ address: this.hotel.additionalInfo.location.trim() }, (results, status) => {
    //   if (status.toString() == "OK") {
    //     var marker = new google.maps.Marker({
    //       map: mapa,
    //       position: results[0].geometry.location,
    //       title: title,
    //     });

    //     google.maps.event.trigger(mapa, "resize");
    //     //colocamos el market
    //     mapa.panTo(marker.getPosition());
    //     mapa.setZoom(16);
    //   } else {
    //     // console.log("No se obtuvo la ubicacion del mapa: " + status);
    //   }
    // });
    // mapa.set("parent", this);
  }

  /**
   * Metodo que crea los select que se veran reflejados en las habitaciones.
   * @param qty Indica la cantidad de select que se crearan (se crea 1 por cada room del hotel).
   * @param maxProductSelect Indica los valores del array (se le agrega 1 mas por el valor 0).
   */
  createSelectsOfRoom(qty: number, maxProductSelect: number) {
    for (let i = 0; i < qty; i++) {
      this.select.push({
        selectArray: Array.from(Array(maxProductSelect).keys()),
        selectValue: 0,
      });
    }
  }

  calculateTotalPrice(room: any) {
    // console.log(room);
    this.totalQty = 0;
    this.disableButton = true;

    if (room == 0) {
      // Manejar el caso de que room sea 0 si es necesario
    } else {
      // Verificar si una habitación con el mismo roomCode ya está en la lista
      // console.log(this.selectedRooms);
      const existingRoomIndex = this.selectedRooms.findIndex(selectedRoom => selectedRoom.room.roomCode === room?.room?.roomCode);
      // console.log(existingRoomIndex);

      if (existingRoomIndex !== -1) {
        // Si la habitación ya está en la lista, agrega una nueva entrada en el array de habitaciones seleccionadas
        const newRoom = {
          ...room,
          productKey: this.getRandom()
        };
        this.selectedRooms.push({
          room: newRoom,
          total: room?.room?.optionNightsNetTotalProf
        });
        // console.log(this.selectedRooms);
        // console.log(room.optionNightsNetTotalProf);
      } else {
        // Si la habitación no está en la lista, agrega una nueva entrada en el array de habitaciones seleccionadas
        const newRoom = {
          ...room,
          productKey: this.getRandom()
        };
        this.selectedRooms.push({
          room: newRoom,
          total: room?.room?.optionNightsNetTotalProf
        });
        // console.log(room.optionNightsNetTotalProf);
      }

      // Calcula el total general sumando los totales de todas las habitaciones seleccionadas
      this.totalQty = this.selectedRooms.reduce((total, selectedRoom) => total + selectedRoom.total, 0);

      // console.log(this.selectedRooms);
      // console.log(this.totalQty);

      this.disableButton = false;
    }
  }

calculateTotalPriceWithout(room: any) {
  const roomNetPrice = room.roomSelect.optionNightsNetTotalProf;

  this.totalQty -= roomNetPrice;

  // Encuentra el índice del objeto en el array this.selectedRooms
  const indexToRemove = room.selectedIndex;

  // Si el índice está dentro del rango del array, elimina el elemento correspondiente
  if (indexToRemove >= 0 && indexToRemove < this.selectedRooms.length) {
    this.selectedRooms.splice(indexToRemove, 1);
  }


  if(this.selectedRooms.length === 0) {
    this.disableButton = true;
  }
}

  modalRomms() {
    this.max = 10;
    this.isReadonly = true;
  }
  modalRoom() {
    this.isReadonly = true;
  }

  continue() {
    let valueLog = JSON.parse(localStorage.getItem("payload"));
    let valueForm = JSON.parse(localStorage.getItem("form"));
    let valueRoom = JSON.parse(localStorage.getItem("form")).room.length;

      for (let index = 0; index < this.selectedRooms.length; index++) {
          this.addItinerary(this.selectedRooms[index].room, this.hotel, index);
      }
      console.log("envio itinerario", this._itineraryService.itinerary);
        this._itineraryService.itinerary.totalTaxPrice = this.totalTax();
        this._itineraryService.itinerary.nights = this._itineraryService.itinerary?.destination[0]?.destination_info.nights;
        this._itineraryService.itinerary.arrivalDate = this._itineraryService.itinerary?.items[0].arrivalDate;
        this._itineraryService.itinerary.departureDate = this._itineraryService.itinerary?.items[0].departureDate;
        this._itineraryService.itinerary.currency = this.currencySelected;
        this._itineraryService.updateItinerary(this._itineraryService.itinerary);
        this.selectedRooms = [];
  }

  totalTax(){
    let taxSum: number = 0;
    const itemTax: any[] = [];
    const items: any[] = this._itineraryService.itinerary.items;
    console.log(this._itineraryService.itinerary)
    console.log(items)

    items.forEach(item => {
      if(item.taxes && item.taxes.length > 0) {

        item.taxes.forEach(tax => {
          console.log(tax)
          itemTax.push(tax);
        })
      }
    })

    itemTax.forEach(tax => {
      if(typeof tax.included === 'boolean' && tax.included) {
        taxSum += tax.amount;
      }
    });

    return taxSum = parseFloat(taxSum.toFixed(2));

  }



  productQtyNowSelect(select: any[]): number {
    let total = 0;
    select.forEach((obj) => (total += +obj.selectValue));
    return total;
  }

  close() {
    this._emitRefModal("close");
  }

  selectedIndexRoom(index: any) {
    this.indexRoom.push(index)
    // console.log(this.indexRoom)
  }

  addItinerary(item: any, hotel: any, roomIndex?: number) {

    // validar si owner esta lleno
      if (!isValidOwener(this._cookieService.get("clientActive"))) {
        this._emitRefModal("warning - Warning - " + fillOwnerMessage());
        return;
      }
console.log(hotel);
console.log(item)
// netPriceBookingFreeTax  230.91
// taxPrice  4.9
// tax 21%
      const adittionalInfo = [...hotel.additionalInfo];

      // hotel.additionalInfo.rooms = []; //lo tuve que comentar dado que no renderizaba los precios en el category-results.
      console.log('hotel ->',hotel);
      
      item.optionNightsNetTotalProf = item?.room?.optionNightsNetTotalProf ? item?.room?.optionNightsNetTotalProf : 0;
      item.taxes = item?.pricing?.taxes ? item?.pricing?.taxes : [];
      item.netPriceProf = item?.room?.netPriceProf ? item?.room?.netPriceProf : null;
      item.optionNightsNetTotalFreeTax = item?.room?.optionNightsNetTotalFreeTax ? item?.room?.optionNightsNetTotalFreeTax : 0; // suma total de las noches sin impuestos
      item.netPriceFreeTax = item.room?.netPriceFreeTax ? item.room?.netPriceFreeTax : 0; // tarifa por noche sin impuesto
      item.taxPrice = item.room?.taxPrice ? item.room?.taxPrice : 0; //monto impuesto por noche
      item.taxNightsPriceTotal = item.room?.taxNightsPriceTotal ?  item.room?.taxNightsPriceTotal : 0; // suma total impuestos de las noches
      item.tax = item?.pricing?.taxAmount ?  item?.pricing?.taxAmount : 0; // valor del iva aplicado
      item.profility = item?.room?.profility ? item?.room?.profility : 'Falta profility';
      item.id = item?.room?.roomId;
      item.searchId = hotel?.searchId;
      item.providerId = item?.provider?.id;
      item.providerName = item?.provider?.name;
      item.nameAdapter = hotel?.nameAdapter;
      item.hotelId = item?.hotelCode;
      item.idProduct = item?.room?.hotelCode;
      item.categoryName = "HOTELS";
      item.title = hotel?.title;
      item.location = hotel?.additionalInfo?.location;
      item.arrivalDate = hotel?.dateIni;
      item.departureDate = hotel?.dateEnd;
      item.updateDate = new Date();
      item.categoryHotel = adittionalInfo[0].starRating;
      item.cancellation_policy = item?.cancellationPolicy;
      item.bookParam = item?.room?.bookParam;
      item.amount = hotel.amount;
      item.imagen = hotel.additionalInfo.images[0]?.image;
      item.hotelAdditionalInfo = adittionalInfo;
      item.hotelAdditionalInfo.coordinates = hotel.coordinates;
      item.description = hotel?.description;
      item.adults = item?.room?.availability?.maxAdults;
      item.childrens = item?.room?.availability?.maxChildren;
      item.roomType = item?.room?.roomType;
      item.boardName = item?.room?.boardName;
      item.rateClassId = item?.room?.rateClass ? item?.room?.rateClass : null;
      item.boardNameCode = item?.room?.boardName ? item?.room?.boardName : null;
      item.roomId = item?.room?.roomId ? item?.room?.roomId : null;
      item.roomCode =  item?.room?.roomCode ? item?.room?.roomCode : null;
      item.currency = item?.room?.pricing?.currency ? item?.room?.pricing?.currency : null;
      item.nights = item.stay.nights;
      item.hotelAdditionalInfo.rooms = [];
      // item.hotelAdditionalInfo.country = 'BR';

      // se agrega el payload de busqueda de producto al objeto rentacar
      this.form = JSON.parse(localStorage.getItem("form"));
      // console.log(this.form)
      if (!this.form || !this.form.room || !Array.isArray(this.form.room)) {
        this.form = JSON.parse(localStorage.getItem("form"));
        this.formAuxArr.push(this.form.room);
        this.form.room = this.formAuxArr;
        // console.log(this.form.room);
      }
      let roomIndexProduct
      if(this.selectedRooms.length <= 1) {
        for( let i = 0; i < this.selectedRooms.length; i++) {
          roomIndexProduct = this.form.room[this.indexRoom[i]];
        }
      } else {
        roomIndexProduct = this.form.room[roomIndex];
      }

      // Obtén el valor de roomDescriptionIndex
      const roomDescriptionIndex = roomIndexProduct
      // Asegúrate de que 'this.form' y 'this.form.room' sean objetos válidos

      for (let i = 0; i < this.selectedRooms.length; i++) {
        let uniqueProductKey = this.getRandom();
        while (this.selectedRooms.some(room => room.room.productKey === uniqueProductKey)) {
          uniqueProductKey = this.getRandom();
        }
        this.selectedRooms[i].room.productKey = uniqueProductKey;
        // console.log(this.selectedRooms[i]);
      }


      // Actualiza 'room' en el formulario
      this.form.room = roomDescriptionIndex;
      console.log(this.form.room)
      item.form = this.form;
      console.log(item)
      item.payloadUpdate = this._payloadUpdateHotel(this.form, item); // ( this.form.value
      item.payloadOriginal = this._getPayloadOriginalInfo(this.form, item);
      item.destination = this._itineraryService.getDestinationInfo(this.form, item);
      console.log(item)
      console.log(JSON.stringify(item))
    if (this.itineraryItem) {
      item.destination = this.itineraryItem.destination;
      this._itineraryService.remove(this.itineraryItem, item);
      this._emitRefModal("success - Congratulations - Product updated succesfully");
      this.toastr.success("Congratulations !!", "Product Successfully updated");
    } else {
      console.log("item antes de guardar", item);
      this._itineraryService.sendMessageSubscriptorLoading(true);
      this._itineraryService.add(item).subscribe(
        (response: any) => {
          console.log("Add-Success: ", response);
          if (typeof response === "string") {
            this._emitRefModal("error - Error - " + response);
          } else {
            // console.log('paso por success')
            this._itineraryService.currencySuccessEmit.next(item.currency);
            this._emitRefModal("success - Congratulations - Product added succesfully to your itinerary");
            this.toastr.success("¡Felicitaciones! Producto agregado con éxito. ");
          }
          this._itineraryService.evaluateDateItineraryRange(this._itineraryService.itinerary["items"]);
        },
        (error) => {
          console.log("Add-Error: ", error);
          this._emitRefModal("error - Error - Item was not added to your itinerary");
          this._itineraryService.sendMessageSubscriptorLoading(false);
        }
      );
    }
    this._itineraryService.lastItemHotel$.next(item);
  }

  getOthers() {
    // console.log("payloadUpdate", this.payloadUpdate);
    const others = {
      maxRequestTimeOnSeconds: 20,
      minNumResults: 3,
      roomConfiguration:
        // tslint:disable-next-line:max-line-length
        this.payloadUpdate.others !== undefined ? this.payloadUpdate.others.roomConfiguration : this.payloadUpdate.products[0].others,
    };

    return others;
  }

  _getPayloadOriginalInfo(form: any, item: any): Object {
    let others;
    let payload;
    console.log(form)
    console.log("_getPayloadOriginalInfo", item)
    const pax = JSON.parse(localStorage.getItem("payload"));
    const formOriginal = JSON.parse(localStorage.getItem("form"));
    const formLength = formOriginal.room.length;
    // console.log(pax)
    // console.log(this.origin);
    if (this.origin === undefined || this.origin == "HOTELS") {

      this._getRoomDistOriginal(form,item);
      if(this.selectedRooms.length < formLength) {
        const dist = this.roomDist.filter((_, index) => index < formLength);
        this.roomDist = dist;
      }
      others = {
        agedriver: environment.agedriver,
        serialized: environment.serialized,
        maxRequestTimeOnSeconds: environment.maxRequestTimeOnSeconds,
        minNumResults: environment.minNumResults,
        maxNumResultsPerAdapter: environment.maxNumResultsPerAdapter,
        hotels: {
          roomConfiguration: this.roomDist,
        },
      };
    }

    payload = {
      products: [
        {
          providerId: item.providerId,
          nameAdapter: item.nameAdapter,
          categoriesId: [1], // Hotel
          productId: item.room.hotelCode,  //this.hotel.id,//
          productName: item.title,
          origin: this.hotel.origin,
          destination: this.hotel.destination,
          city: " ", //form.destination.cityName,
          priceFrom: 0,
          priceTo: 0,
          arrivalDate: item.arrivalDate,
          departureDate: item.departureDate,
          qtyProduct: 1,
          qtyPax: pax[0].qtyPax,
          others: others,
          agencyId: pax[0].agencyId,
          nationality: form.nationality
        },
      ],
    };
    // console.log("item _payloadUpdateHotel ", payload);
    return payload;

  }

  roomDist = [];

  _getRoomDistOriginal(form: any, item: any) {
      let roomsArr = [];
      const rooms = form.room;
      const formOriginal = JSON.parse(localStorage.getItem("form"));
      const formLength = formOriginal.room.length;
      roomsArr.push(rooms)

      if(this.selectedRooms.length < formLength) {

        for (let i = 0; i < roomsArr.length; i++) {
          this._roomsOriginal(form,item, formLength);
        }

      } else if (this.selectedRooms.length == formLength) {
        for (let i = 0; i < roomsArr.length; i++) {
          console.log('item room ->',item)
          const people = [];
          const dist: RoomConfigurationItem = {
            adults: 0,
            children: [],
            boardNameCode: item.boardNameCode,
            condition: item.rateClassId,
            roomId:item.roomId,
            roomCode:item.roomCode,
            bookingCode: item.bookParam,
          };
            // console.log('dist', dist)
          const adults = rooms.qtyAdults;
          const children = rooms.childsage;
          for (let j = 0; j < children.length; j++) {
            people.push(children[j].age);
          }
          dist.adults = adults;
          dist.children = people;
          console.log(dist)
          this.roomDist.push(dist);
          console.log(this.roomDist)
      }

      return this.roomDist;
      }

  }

  _roomsOriginal(form: any, item: any, formLength: any) {
    const rooms = form.room;
    const roomsArr = [];

    // Agregar elementos a roomsArr
    for(let i = 0; i < this.selectedRooms.length; i++) {
      roomsArr.push(this.selectedRooms[i].room);
    }

    for (let i = roomsArr.length; i < formLength; i++) {
      const newItem = { ...item }; // Clonar el elemento inicial en cada iteración
      newItem.boardNameCode = -1;
      newItem.condition = -1;
      newItem.roomId = -1;
      newItem.roomCode = -1;
      newItem.bookingCode = -1;
      roomsArr.push(newItem); // Agregar el clon al array
    }

    for (let i = 0; i < roomsArr.length; i++) {
      console.log('roomsarr ->', roomsArr);
      const people = [];
      const dist: RoomConfigurationItem = {
        adults: 0,
        children: [],
        boardNameCode: roomsArr[i].boardNameCode,
        condition: roomsArr[i].rateClassId,
        roomId: roomsArr[i].roomId,
        roomCode: roomsArr[i].roomCode,
        bookingCode: roomsArr[i].bookParam
      }
      const adults = rooms.qtyAdults;
      const children = rooms.childsage;
      for (let j = 0; j < children.length; j++) {
        people.push(children[j].age);
      }
      dist.adults = adults;
      dist.children = people;
      this.roomDist.push(dist);
      console.log(this.roomDist)
    }
    return this.roomDist;
  }

  _payloadUpdateHotel(form: any, item: any): Object {
    // console.log("item _payloadUpdateHotel", item);
// console.log(item)
// console.log('form modal hotel ->',form)
    let payloadSearch = JSON.parse(localStorage.getItem('payload'));

    let qtyPax;
    let others;
    let payload;
    // console.log(this.origin);
    if (this.origin === undefined || this.origin == "HOTELS") {
      others = {
        agedriver: environment.agedriver,
        serialized: environment.serialized,
        maxRequestTimeOnSeconds: environment.maxRequestTimeOnSeconds,
        minNumResults: environment.minNumResults,
        maxNumResultsPerAdapter: environment.maxNumResultsPerAdapter,
        hotels: {
          roomConfiguration: this._getRoomDist(form,item),
        },
      };
    }
console.log(item  )
    payload = {
      products: [
        {
          providerId: item.providerId,
          nameAdapter: item.nameAdapter,
          categoriesId: [1], // Hotel
          productId: item.room.hotelCode,  // this.hotel.id
          productName: item.title,
          origin: this.hotel.origin,
          destination: this.hotel.destination,
          city: " ", //form.destination.cityName,
          priceFrom: 0,
          priceTo: 0,
          arrivalDate: item.arrivalDate,
          departureDate: item.departureDate,
          qtyProduct: 1,
          qtyPax: item.room?.availability.maxPax,
          others: others,
          agencyId: payloadSearch[0].agencyId,
          nationality: form.nationality
        },
      ],
    };
    // console.log("item _payloadUpdateHotel ", payload);
    return payload;
  }

  getRandom() {
    return Math.floor(Math.random() * 20000000000000) + Date.now();
  }

  _getRoomDist(form?: any, item?: any) {

    console.log(form)
    console.log(item)
    const roomDist = [];
    const rooms = form.room;
    let roomsArr = [];
    roomsArr.push(rooms)
    // console.log(roomsArr)
    for (let i = 0; i < roomsArr.length; i++) {
      const people = [];
      const dist: RoomConfigurationItem = {
        adults: 0,
        children: [],
        boardNameCode: item.boardNameCode,
        condition: item.rateClassId,
        roomId:item.roomId,
        roomCode:item.roomCode,
        bookingCode: item.bookParam
      };
        // console.log('dist', dist)
      const adults = roomsArr[i].qtyAdults;
      const children = roomsArr[i].childsage;
      for (let j = 0; j < children.length; j++) {
        people.push(children[j].age);
      }
      dist.adults = adults;
      dist.children = people;
      roomDist.push(dist);

  }
  return roomDist;
}

  _emitRefModal(status: string) {
    this.refModal.emit(status);
  }

  // imgError(image) {
  //   image.style.display = "none";
  // }

  // loadImages() {
  //   // console.log("hotel", this.hotel);
  //   // console.log("leng imag", this.hotel.additionalInfo.images.length);

  //   for (let i = 0; i < this.hotel.additionalInfo.images.length; i++) {
  //     const img = this.hotel.additionalInfo.images[i];
  //     this.imgList.push(img);
  //   }
  // }

  // prev() {
  //   if (this.imgSelect > 0) {
  //     this.imgSelect = this.imgSelect - 1;
  //   }
  //   if (this.imgSelect <= 5) {
  //     this.scroll("l");
  //   }
  // }

  // next() {
  //   if (this.imgSelect < this.imgList.length - 1) {
  //     this.imgSelect++;
  //   }
  //   if (this.imgSelect >= 5) {
  //     this.scroll("r");
  //   }
  // }
  // scroll(dir) {
  //   let idc = "slide-miniatura";
  //   let content = document.getElementById(idc);
  //   const stepr = 150;
  //   const stepl = -150;
  //   if (dir == "l") {
  //     content.scrollLeft += stepl;
  //   } else if (dir == "r") {
  //     content.scrollLeft += stepr;
  //   }
  // }

  // Pasar primer letra de cada palabra a mayuscula en una cadena
  stingUpperCase(str) {
    if (str) {
      let e = str.toLowerCase();
      return e.replace(/\b\w/g, (l) => l.toUpperCase());
    }
  }

  getRequirements(){
    this.hotel.additionalInfo.checkin_requirement.forEach((e:any) => {
      this.arrRequirements.push(e);

    });

    this.hotel.additionalInfo.covid_requirement.forEach((e:any) => {
      this.arrRequirementsCovid.push(e);

    });

    this.hotel.additionalInfo.other_requirement.forEach((e:any) => {
      this.arrRequirementsOther.push(e);

    });

    this.hotel.additionalInfo.other_services_extra.forEach((e: any) => {
      this.otherService.push(e);
    })

    if(this.hotel.additionalInfo.rooms[0].room.pricing.taxes) {
      this.arrOthersTax.push(this.hotel.additionalInfo?.rooms[0]?.room?.pricing.taxes[0]);
    }
    console.log(this.arrOthersTax)

  }

  getObservations() {
    if(this.hotel.additionalInfo.amenities.length > 0) {


    this.hotel.additionalInfo.amenities.forEach((e:any) => {

      if (e.ame_subtype === 'Accesabilidad') {
        const nameObservation = 'Accesabilidad';

        // Añadir nameObservation solo si no está presente en facility
        const existingItem = this.arrFacilitiesAndService.find(item => item.facility === nameObservation);

        if (!existingItem) {
          this.arrFacilitiesAndService.push({ facility: nameObservation, services: [] });
        }
        // Añadir todos los elementos de e.name al array services
        const arrObservation = this.arrFacilitiesAndService.find(c => c.facility === nameObservation);
        arrObservation.services.push(e.name);
      }
      if(e.ame_subtype === 'Comida') {
        const nameObservation = 'Comida';
        // Añadir nameObservation solo si no está presente en facility
        const existingItem = this.arrFacilitiesAndService.find(item => item.facility === nameObservation);

        if (!existingItem) {
          this.arrFacilitiesAndService.push({ facility: nameObservation, services: [] });
        }
         // Añadir todos los elementos de e.name al array services
         const arrObservation = this.arrFacilitiesAndService.find(c => c.facility === nameObservation);
         arrObservation.services.push(e.name);
      }
    });
    console.log(this.arrFacilitiesAndService)
    }
  }

/**
 * Función servicesExtras: Extrae y transforma la información de servicios adicionales de un hotel.
 *
 * @description Esta función toma la información de servicios extras de un hotel y la transforma en un array de objetos con propiedades 'name' y 'value'.
 *               Luego, elimina los primeros dos elementos de dicho array.
 *               Finalmente, imprime el array resultante en la consola.
 *
 * @memberof NombreDelComponenteOClase
 * @method servicesExtras
 * @returns {void} No devuelve nada, solo imprime el resultado en la consola.
 */
servicesExtras(): void {
      // Objeto de mapeo para traducción de nombres de servicios
    const servicesTraductor = {
      accept_babies: 'aceptan bebés',
      baby_price_has_cost: 'tienen costo extra los bebes',
      accept_children: 'aceptan niños/as',
      children_price_has_cost: 'tienen costo extra los/as niños/as',
      baby_has_extra_bed: 'el bebé tiene cama extra',
      extra_bed_baby_has_cost: 'tiene costo extra la cama del bebé',
      accept_pets: 'aceptan mascotas',
      cleaning_service: 'servicio de limpieza',
      reservation_fee: 'cuota de reserva',
      city_tax: 'impuesto municipal',
      city_tax_has_cost: 'tiene costo el impuesto municipal',
      iva_tax: 'impuesto iva'
    };

  // Verifica si hay información de servicios extras en el hotel
  if (this.hotel.additionalInfo.services_extra.length > 0) {
    // Transforma la información en un array de objetos con propiedades 'name' y 'value'
    const keyValueObjectsArray = this.hotel.additionalInfo.services_extra.reduce((accumulator: any[], currentObject: any) => {
      // Mapea las entradas del objeto a un array de objetos con propiedades 'name' y 'value'
      const pairs = Object.entries(currentObject).map(([name, value]) => ({ name: servicesTraductor[name] || name, value }));
      // Concatena los arrays de pares 'name'-'value' al acumulador
      return accumulator.concat(pairs);
    }, []).slice(2); // Elimina los primeros dos elementos del array resultante

    // Imprime el array resultante en la consola
    this.arrServicesExtras = keyValueObjectsArray;
  }
}



  booleanGenerals():void{
    // console.log('amenitiesInfo ->', this.amenitiesHotelInfo);

    if(this.amenitiesHotelInfo.length === 0){
      this.amenitiesBoolean = false;
    }

    if(this.arrHowToGet.length === 0){
      this.howToGetBoolean = false;
    }

    if(this.hotel.additionalInfo.atracttions.length === 0){
      this.atracttionsBoolean = false;
    }

    if(this.hotel.additionalInfo.frequentquestions.length === 0){
      this.frequentsQBoolean = false;
    }

    if(this.hotel.additionalInfo.starRating === '0' || this.hotel.additionalInfo.starRating === ''){
      this.starRate = false;
    }
  }

  toggleTextComplete(){
    this.descriptComplete = !this.descriptComplete;
    if(this.descriptComplete){
      this.toggleDescript = 'Ver Menos';
    }else{
      this.toggleDescript = 'Ver Más';
    }
  }

  verifyConditionInfo(hotelInfo: any): boolean {
    return Number(hotelInfo) > 0;
  }

}
