import { CookieService } from "ngx-cookie";
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChildren } from "@angular/core";
import { RestService } from "../shared/rest.service";
import { ApiService } from "../itinerary/shared/api.service";
import { ItineraryService } from "../shared/itinerary.service";
import { ActivatedRoute, Router, NavigationExtras } from "@angular/router";
import { DatePipe } from "@angular/common";
import { SortByDestinationPipe } from "../shared/sortByDestination.pipe";
import { UserService } from "../pages/com.usblick.common/auth/user.service";
import { NgxSpinnerService } from "ngx-spinner";
import { NrPrepaymentModalComponent } from "../shared/nr-prepayment-modal/nr-prepayment-modal.component";
import { NrBlockModalComponent } from "../shared/nr-block-modal/nr-block-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { FidelityService } from "../fidelity/fidelity.service";
import * as moment from "moment";
import { ModalPostBookingComponent } from "../shared/modal-post-booking/modal-post-booking.component";
import { MaterialModalAlertComponent } from "../shared/material-modal-alert/material-modal-alert.component";
import { ModalSyncProductComponent } from "../shared/modal-sync-product/modal-sync-product.component";
import { setting } from "../../../setting";
import { AuthorizationService } from "app/core/authorization/authorization.service";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { AppState } from "app/core/profiles/storeProfile";
import { selectPermissions } from "app/core/profiles/profiles.selectors";
@Component({
  selector: "usb-itinerary-detail",
  templateUrl: "./itinerary-detail.component.html",
  styleUrls: ["./itinerary-detail.component.scss"],
  providers: [SortByDestinationPipe, DatePipe],
})
export class ItineraryDetailComponent implements OnInit, AfterViewInit {
  @ViewChildren("card") cardItinerary: any;
  @Input() appLoading: boolean = true;
  @Input() itemsSync: [] = [];
  @Input() itineraryId: any;
  @Input() results: boolean = false;
  @Output() parcialBlickoinsUpdated = new EventEmitter<number>();
  @Output() shouldContinueEvent = new EventEmitter<boolean>();
  partialReservationArray: any;
  status: string;
  id: number;
  idReservation: number;
  idConfirmation: number;
  totalNetPriceBookingFreeTax: number;
  totalTaxNightsPriceTotal: number;
  itinerary: any;
  screen: string;
  goTo: string = "";
  itemsArray: any[];
  destinationsArray: any;
  empty: boolean;
  _cookieClient: any = undefined;
  isUpdate: boolean = false;
  reservations: any;
  activeButtonPartialReserve: boolean = false;
  isActivePartialReserve: boolean = false;
  qtyItemsToPartial: number = 0;
  modifications: boolean = false;
  priceAgent:number = 0;
  public getClientsSettingAppBooking: any;
  public parcialBlickoins: number = 0;
  itineraryLoaded: boolean = false;
  public showTextChangeDetailsProducts: boolean = false;
  public changePriceFromReserve: boolean = true;
  public itemChangeTwoProduct: boolean = false;
  showBoton: boolean = true;
  showPartialContainer: boolean = false;
  partialBlickoinsAfterInit: number = 0;
  public taxes: any[] = [];
  public taxSum: number = 0;
  public dataAux: any;

  public statusArray = {
    update: null,
    available: false,
    samePrice: false,
    modifications: false,
    verified: false,
    reserved: false,
  };

  permissions$: Observable<string[]>;
  permissionsMap: { [key: string]: boolean } = {};

  totalNetPriceBooking: number;
  netPriceBookingFreeTax: any;
  netPriceBooking: any;


  constructor(
    public restService: RestService,
    private apiService: ApiService,
    public itineraryService: ItineraryService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private router: Router,
    private destinationPipe: SortByDestinationPipe,
    private _cookieService: CookieService,
    public userService: UserService,
    private ngxSpinner: NgxSpinnerService,
    private dialog: MatDialog,
    public fidelityService: FidelityService,
    private store: Store<AppState>
  ) {

    this.partialReservationArray = [];
    this.empty = false;
    this.getClientsSettingAppBooking = JSON.parse(localStorage.getItem(setting.name));


  }

  ngOnInit() {
    this.permissions$ = this.store.select(selectPermissions);
    // Despacha las acciones para cargar roles y permisos
    this.store.dispatch({ type: '[Auth] Load Permissions' });

    this.permissions$.subscribe(permissions => {
      this.permissionsMap = this.createPermissionsMap(permissions);
    });

    this.results = false;
    this.status = "false";
    this.getItinerary("false");
    this.getDetailPricesCards();
    this.userService.activeGlobalFee = false;
    this.verificarCondiciones();
    this.destinationsArray;

      console.log(this.dataAux)
      if(this.dataAux) {
        this.dataTax(this.dataAux);
    }
  }

  createPermissionsMap(permissions: any[]): { [key: string]: any } {
    const map: { [key: string]: any } = {};
    permissions.forEach((permission) => {
      // Utiliza el nombre del permiso como clave
      map[permission.name] = permission;
    });
    return map;
  }

    verificarCondiciones() {
      if (this.itinerary) {
        this.showBoton = (this.itinerary.items.length > 1 &&
                          this.itinerary.status_id != 3 &&
                          this.itinerary.status_id != 4 &&
                          this.itinerary.status_id != 5 &&
                          this.itinerary.status_id != 6 &&
                          this.activeButtonPartialReserve);
      }
    }

    ngAfterViewInit() {
      this.verificarCondiciones();
      this.showPartialContainer = false;
    }

    controlShow() {
      this.showPartialContainer = !this.showPartialContainer;
    }


  _reloadItinerary() {
    console.log("_reloadItinerary itineray-detail");
    this.getItinerary("false");
    console.log('itinerary actualizado -->', this.itineraryService);

     window.location.reload();
    // this.userService.activeGlobalFee = false;
  }

  getDetailPricesCards() {
    if(this.id > -1) {

      let url = "products/getPriceAgent/" + this.id;

      this.restService.doGet("app", url).subscribe(
        (data: any) => {
          //console.clear();
          // console.log("data response GET itinerayDetail", data);
          if(Object.keys(data).length > 0) {
          this.userService.itinerayDetailFee = data;
          console.log(data)
          // console.log(JSON.parse(data.products));
          // const item = JSON.parse(data.products.item);
          // console.log(item)
          // this.taxes =  item.taxes;
          // console.log(this.taxes)
          this.dataAux = data.products


      // Uso de la función para calcular la suma de netPriceBookingFreeTax
      this.netPriceBookingFreeTax = this.sumPropertyValues(data.products, 'netPriceBookingFreeTax');

      // Uso de la función para calcular la suma de taxNightsPriceTotal
      this.netPriceBooking = this.sumPropertyValues(data.products, 'netPriceBooking');
      this.netPriceBookingFreeTax = this.netPriceBookingFreeTax.toFixed(2);
      this.netPriceBooking = this.netPriceBooking.toFixed(2);
          // if(!this.userService.itinerayDetailFee.percentageFee){
          //   this.userService.itinerayDetailFee.percentageFee = 0.0;
          //   this.userService.itinerayDetailFee.totalFee = 0.0;
          //   this.userService.itinerayDetailFee.totalPriceAgent = 0.0;
          // }
          this.userService.priceGeneralData = data.tnetPriceBookingFreeTax;
          // console.log("this.userService.itinerayDetailFee", this.userService.itinerayDetailFee);

          if (this.screen == "addFee") {
            //this.getReservations();
            //this.getDetailPricesCardsReservation();

            console.log("screen add Fee");
            this.userService.sendPrices$(data);
          } else {
            this.userService.sendPrices$(data);
          }
        } else {

        }
        this.appLoading = false;
          //  this.ngxSpinner.hide();
        },
        (error) => {
          console.log("error response", error);
        }
      );
    }
  }

  private dataTax(data: any) {
    console.log(data);
    if (data && data.length > 0) {
      // Recorre cada producto en los datos
      data.forEach((product) => {
        // Verifica si existe un ítem y si es un string
        if (product.item && typeof product.item === 'string') {
          // Convierte el ítem en un objeto JSON
          const item = JSON.parse(product.item);
          // Verifica si hay taxes en el ítem
          if (item.taxes && Array.isArray(item.taxes)) {
            // Recorre cada impuesto en las taxes
            item.taxes.forEach((tax) => {
              // Verifica si 'included' es un booleano verdadero
              if (typeof tax.included === 'boolean' && tax.included) {
                // Si 'included' es true, suma el impuesto al total
                this.taxSum += tax.amount;
              }
            });
          }
        }
      });
    }
    // Formatea el resultado con dos decimales
    this.taxSum = parseFloat(this.taxSum.toFixed(2));
    console.log('Suma total de impuestos:', this.taxSum);
  }

  sumPropertyValues(data: any[], propertyName: string): number {
    return data.reduce((total, item) => {
      // Asegurarse de que el objeto tenga la propiedad deseada
      if (item[propertyName]) {
        return total + item[propertyName];
      }
      return total;
    }, 0);
  }

  getDetailPricesCardsReservation() {
    let url = "reservations/getPriceAgent/" + this.idReservation;

    this.restService.doGet("app", url).subscribe(
      (data: any) => {
        console.log("data response GET price agent", data);
        this.userService.itinerayDetailFee = data;
        this.userService.itinerayDetailFee = data;
        console.log("this.userService.itinerayDetailFee", this.userService.itinerayDetailFee);
      },
      (error) => {
        console.log("error response", error);
      }
    );
  }

  _modifications() {

    this.updateCards(false);
  }

  resetPartial() {
    this.itineraryService.updateRemoveItineraryPartial(this.id);
  }

  getReservations() {}

  findReservedProduct(item) {
    if (this.reservations) {
      if (this.reservations != "Data Not Found") {
        let dato = this.reservations.find((element) => item.item.title == element.resultReserve.title);
        if (dato) {
          return dato;
        }
      }
    }
  }

  getItinerary(isSinc) {
    this.route.queryParams.subscribe((params) => {
      this.itineraryLoaded = false;
      this.id = Number.parseInt(params["id"]);
      // this.status = params["stat"];
      this.screen = params["screen"];
      if (params["goTo"]) {
        this.goTo = params["goTo"];
      }
      this.idReservation = Number.parseInt(params["reservationId"]);
      if (this.id) {
        this.apiService.doGet("itineraries/" + this.id).subscribe(
          (response: any) => {
            console.log("getItinerary", response);
            this.itinerary = response;
            this.userService.items = response.items;
            const syncProduct = this.userService.items.map(c => c.syncProduct);
            console.log(syncProduct)
            // Filtrar elementos undefined del array
            const filteredSyncProduct = syncProduct.filter(Boolean);

            if (filteredSyncProduct.length > 2) {
              const productAlertMessages = filteredSyncProduct.filter(c => c.alert === true);

              if (productAlertMessages.length > 0 && filteredSyncProduct.length === 2) {
                this.itemChangeTwoProduct = true;
              }
}
console.log(filteredSyncProduct);
            if (this.screen == "detail" || this.screen == "addFee") {
              this.getDetailPricesCards();
            }
            if (response.items.length > 0) {
              if ((this.screen == "detail") && (isSinc =="false")) {
                // console.log("resetPartial", isSinc);
                this.resetPartial();
                //valido si hay mas de 1 items en save para activar el boton de partial
                let index = 0;
                response.items.forEach((item) => {
                  if (item.status_id == 1) {
                    index++;
                  }
                });
                if (index > 1) {
                  this.activeButtonPartialReserve = true;
                }
              }
              this.updateItinerary();
              this.itineraryLoaded = true;
              //     this.updateCards();
            } else {
              this.empty = true;
              this.router.navigateByUrl("/home");
            }
          },
          (error) => {
            console.log("Error: " + error);
          }
        );
      }
    });
  }

  emitShouldContinue(shouldContinue: boolean) {
    // console.log(shouldContinue)
    this.shouldContinueEvent.emit(shouldContinue);
  }

  public async updateCards(reserve) {
    // Tu código actual aquí
    // Agregar una Promesa para indicar que la función ha terminado
    return new Promise<void>((resolve) => {
      this.getItinerary(false);
      setTimeout(() => {
        this.modifications = false;
        this.syncProduct(reserve);
        resolve(); // Resolvemos la Promesa para indicar que ha terminado
      }, 1000);
    });
  }

  /// modal loading
  openModalSyncProduct(data: any, i: any) {
    var dialogRef = this.dialog.open(ModalSyncProductComponent, {
      width: "500px",
      id: "loadModal" + i,
      data: data,
      disableClose: true,
    });
  }

  async syncProduct(reserve) {
    this.itemsArray = [];
    let itemUpdated;
    let payload: any;
    let booleanSync:boolean;
    var i = 0;
    var qtyUp_ = 0;
    this.itemsSync;
    var codeStatus = false;
    // console.log(this.itemsSync)
    if(this.itemsSync.length < 1) {
      this.itemsSync = this.itinerary.items.filter(object => { return object['status_id'] == 1 });

    }
// console.log(this.itemsSync)
    for (const item of this.itemsSync) {
      await this.openModalSyncProduct(item, i++);
    }

    if (this.itemsSync.length > 0) {
      // console.log("syncProducts");
      // console.log(JSON.stringify(this.itemsSync));

      try {
        console.log('itemSync', this.itemsSync);
        const data = await this.restService.doPost("app", "syncProducts", this.itemsSync).toPromise();
        setTimeout(() => {
          console.log("data syncProducts", data);
          this.getItinerary("true");
          this.dialog.closeAll();
          if (reserve) {
            console.log("item actualizado ", data);
            if (data['verif']){
              // console.log('data verif ->', data.verif);
              console.log(this.results)
              if(this.results) {
                this.emitShouldContinue(false);
              }

              if(!this.results) {
                if (this.isActivePartialReserve && this.qtyItemsToPartial > 0){
                  this.partialReserve();

                } else {
                  this.reserveAll();
                }
              }

            }else {
              if(!this.results) {
                this.resetPartial();
                this.changePriceFromReserve = true;
              }
              if(this.results) {
                this.emitShouldContinue(true);
              }
              const dialogRef = this.dialog.open(MaterialModalAlertComponent, {
                height: "auto",
                width: "500px",
                data: "Hubo un cambio en los productos Agregados por favor revise",
                disableClose: true,
              });

              dialogRef.afterClosed().subscribe(result => {
                const currentRoute = this.router.url;
                if(this.itineraryId) {
                   if (currentRoute !== `/itinerary-detail?id=${this.itineraryId}&stat=false&screen=detail`) {
                 this.router.navigateByUrl(`/itinerary-detail?id=${this.itineraryId}&stat=false&screen=detail`);
                }
                // console.log('result', result);
                }
              });

            }
          }
        }, 750);
      } catch (error) {
        console.log(error);
        this.dialog.closeAll();
      }
    } else {
      const dialogRef = this.dialog.open(MaterialModalAlertComponent, {
        height: "auto",
        width: "400px",
        data: "No existe productos para sincronizar",
        disableClose: true,
      });

      dialogRef.afterClosed().subscribe(result =>{
        // console.log('result', result);

      })
    }
  }


  updateItinerary() {
    this.appLoading = true;
    this.itemsArray = [];
    this.itinerary.items.forEach((item) => {
      /// no actualiza por ser estatus difentes a saved
      this.statusArray.available = false;
      this.statusArray.samePrice = false;
      this.statusArray.modifications = false;
      this.statusArray.verified = false;
      this.noUpdatePrice(item);
      if (this.itemsArray.length === this.itinerary.items.length) {
        this.sortItemsByDestination();
      }
    });
    this.appLoading = false;
  }

  noUpdatePrice(item: any) {
    this.itemsArray.push({
      arrayItem: null,
      item: item,
      result: null,
      available: this.statusArray.available, // false
      samePrice: this.statusArray.samePrice, // false
      price: item.totalPrice,
      modifications: this.statusArray.modifications, ///
      verified: this.statusArray.verified, // false
      dateTimeVerifed: moment(new Date(), "YYYY-MM-DDTHH:mm"),
      reserved: this.statusArray.reserved,
      newNetPriceProvider: 0,
      newNetPriceUsblick: 0,
      newNetPriceAgent: 0,
      newBlickCoins: 0,
      roomAvailable: 0,
    });

    // console.log("noUpdatePrice  this.itemsArray", this.itemsArray);
  }

  returnNetPrice(item): any {
    let price;
    switch (item.categoryName) {
      case "HOTELS":
        price = item.optionNightsNetTotal;
        break;

      case "TOURS":
        //     price = Number.parseInt(item.totalPrice);
        break;

      default:
        break;
    }

    return price;
  }

  sortItemsByDestination() {
    this.destinationsArray = this.destinationPipe.transform(this.itinerary, this.itemsArray, this.datePipe);
  }

  getDatesRange() {
    const destination = this.itinerary.destination;
    if (destination.length > 0) {
      const arrival = this.itinerary.arrivalDate
        ? this.datePipe.transform(this.itinerary.arrivalDate.toString(), "MMM dd, yyyy")
        : this.datePipe.transform(this.itinerary.arrivalDate.toString(), "MMM dd, yyyy");
      const departure =
        destination.length === 0 ? "" : " to " + this.datePipe.transform(this.itinerary.departureDate.toString(), "MMM dd, yyyy");
      return arrival + departure;
    } else {
      return "Dates not defined";
    }
  }

  deleteItem(id: string) {
  //  this.ngxSpinner.show();
  this.appLoading = true;
    this.destinationsArray.forEach((destination, index) => {
      destination.items.forEach((item, i) => {
        if (item.item.id === id) {
          this.destinationsArray[index].items.splice(i, 1);
          this.getDetailPricesCards();
        }
      });
      if (destination.items.length === 0) {
        this.destinationsArray.splice(index, 1);
      }
      this.appLoading = false;
    });

    if (this.destinationsArray.length == 0) {
      this.router.navigateByUrl("/home");
    }
  }

  cancelReservations(id: string) {
    this.ngxSpinner.show();
    this.destinationsArray.forEach((destination, index) => {
      destination.items.forEach((item, i) => {
        if (item.item.id === id) {
          this.destinationsArray[index].items.splice(i, 1);
        }
      });
      if (destination.items.length === 0) {
        this.destinationsArray.splice(index, 1);
      }
    });

   setTimeout(() => {
    //this.updateItinerary();
      this.getItinerary(false);
    ///this.ngxSpinner.hide();
      this.userService.updateProductsPrices(this.itinerary.id);
    }, 200);
  }

  save() {
    let goToURL: string;
    let navigationExtras: NavigationExtras;

    goToURL = "DetailsSummaryItinerary";
    navigationExtras = {
      queryParams: {
        from: "save-draft-reserve",
        itineraryId: this.itinerary.id,
      },
    };

    this.router.navigate([goToURL], navigationExtras);
  }

  reserve() {
    this.itineraryService.updateParcialBlickoins(this.parcialBlickoins);
    this.updateCards(true);
    let invalidOwner = false;
    this._cookieClient = this._cookieService.get("clientActive") != undefined ? this._cookieService.get("clientActive") : undefined;
    if (this._cookieClient !== undefined) {
      let data = this._cookieClient.split("||");
      if (data[2].trim() == "" || data[2].trim() == "false" || data[4].trim() == "" || data[3].trim() == "") {
        invalidOwner = true;
      }
    }
    if (!invalidOwner) {
      let nr = false;
      this.userService.itineraryActive[0].items.forEach((element) => {
        if (element.nr == true) {
          this.openModalNR();
          nr = true;
          return;
        }
      });
      if (nr) {
        return;
      }
    }
  }

  reserveAll() {
    const messageAlert = "You cannot make a reservation of a product with changes, please solve your alerts or just select specific products.";
   /* if (this.status === "false") {
      this.updateCards();
      setTimeout(() => {}, 6000);
    }*/
   console.log(this.cardItinerary)
    let ok = true;
    this.cardItinerary._results.forEach((card) => {
      if (card.item.modifications) {
        console.log('modifications ->', card.item.modifications);

        ok = false;
      }
    });
    if (ok) {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          from: "total-reserve",
          //'step': 'two',
          itineraryId: this.id,
        },
      };
      this.userService.stepReservation = "two";
      this.userService.itineraryReserveId = this.id;
      // setTimeout(() => {
        this.router.navigate(["itinerary-reservation"], navigationExtras);
    //  }, 2000);
    } else {
      this.router.navigate(["/summary"]);
      // this.toastr.error('Error', messageAlert);
    }
  }
  reserveItem(item: any) {
    this.partialReservationArray.push(item);
  }

  partialReserve() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        from: "partial-reserve",
        step: "two",
        itineraryId: this.id,
      },
    };
    // setTimeout(() => {
      this.router.navigate(["itinerary-reservation"], navigationExtras);
    // }, 2000);
  }
  /// Metodo para verificar si un room ha cambiado

  orderPriceLowHotel(data: any) {
    return data.sort(
      (a, b) =>
        Math.min.apply(
          null,
          a.additionalInfo.rooms
            .sort(function (a, b) {
              return a.netPriceProf - b.netPriceProf;
            })
            .map((resultObj) => resultObj.netPriceProf)
        ) -
        Math.min.apply(
          null,
          b.additionalInfo.rooms
            .sort(function (a, b) {
              return a.netPriceProf - b.netPriceProf;
            })
            .map((resultObj) => resultObj.netPriceProf)
        )
    );
  }

  getItemUpdated(item: any, data: any) {
    let itemArray = [];
    let modifications = false;
    let samePrice = false;
    let available = false;
    let verified = false;
    let roomAvailable = false;
    let newNetPriceProvider = 0;
    let newNetPriceUsblick = 0;
    let newNetPriceAgent = 0;
    let newBlickCoins = 0;
    let newCreditBlickoins = 0;
    let newBlickoinsFactor = 0;
    let newCreditFactor = 0;
    let newRoomType;
    let newPackageId;
    let newDescription;
    console.log("data antes de ordernarla", data);

    switch (item.categoryName) {
      case "HOTELS":
        data.results.sort(
          (a, b) =>
            Math.min.apply(
              null,
              a.additionalInfo.rooms
                .sort(function (a, b) {
                  return a.netPriceProf - b.netPriceProf;
                })
                .map((resultObj) => resultObj.netPriceProf)
            ) -
            Math.min.apply(
              null,
              b.additionalInfo.rooms
                .sort(function (a, b) {
                  return a.netPriceProf - b.netPriceProf;
                })
                .map((resultObj) => resultObj.netPriceProf)
            )
        );
        console.log("data despues de ordernarla", data);
        if (data.results) {
          verified = true;
        }
        break;
      case "TOURS":
        available = data.status ? true : false;
        newNetPriceProvider = item.netPriceProvider;
        if (available) {
          // tslint:disable-next-line:max-line-length


          const _data = data.results[0] ? data.results[0].additionalInfo.tours.options : data.results.additionalInfo.tours.options;
          _data.forEach((option) => {
            if (item.optionId === option.optionId) {
              roomAvailable = true;
              samePrice = option.totalPrice == newNetPriceProvider ? true : false;
              if (!samePrice) {
                newNetPriceUsblick = option.totalPrice;
              }
            }
          });
          if (!roomAvailable) {
            newNetPriceUsblick = _data[0].totalPrice;
            newRoomType = _data[0].optionId;
          }
        }
        break;
      case "PTHEMES":
        available = data.status ? true : false;
        newNetPriceProvider = item.optionTotal;
        if (available) {
          data.results[0].additionalInfo.options.forEach((option) => {
            if (option.name === item.name && option.description === item.description) {
              if (option.optionTotal === item.optionTotal) {
                samePrice = true;
              } else {
                newNetPriceUsblick = option.optionTotal;
              }
              roomAvailable = true;
            }
          });
          if (!roomAvailable) {
            newNetPriceUsblick = data.results[0].additionalInfo.options[0].optionTotal;
            newDescription = data.results[0].additionalInfo.options[0].description;
            newRoomType = data.results[0].additionalInfo.options[0].name;
          }
        }
        break;
      case "INSURANCE":
        available = data.status ? true : false;
        newNetPriceProvider = item.totalPrice;
        if (available) {
          const results = data.results;
          results.forEach((result) => {
            if (result.title === item.title) {
              roomAvailable = true;
              if (result.additionalInfo.totalPrice === item.totalPrice) {
                samePrice = true;
              } else {
                newNetPriceUsblick = result.additionalInfo.totalPrice;
              }
            }
          });
          if (!roomAvailable) {
            newRoomType = data.results[0].title;
          }
        }
        break;
      case "RENT-A-CAR":
        available = data.status ? true : false;
        newNetPriceProvider = item.totalPrice;
        roomAvailable = true;
        /* if (item.idProduct === data.results[0].productId) {

        }*/
        break;
      default:
        newNetPriceProvider = 0;
        newNetPriceUsblick = 0;
        newNetPriceAgent = 0;
        newBlickCoins = 0;
        newCreditBlickoins = 0;
        newBlickoinsFactor = 0;
        newCreditFactor = 0;
        available = false;
        samePrice = false;
        newDescription = "";
        newRoomType = "";
      break;
    }

    console.log("modifications getItemUpdated", modifications);
    console.log("item getItemUpdated", item);

    return {
      item: item,
      result: data,
      available: available,
      newPackageId: newPackageId,
      samePrice: samePrice,
      modifications: modifications,
      verified: verified,
      dateTimeVerifed: moment(new Date(), "YYYY-MM-DDTHH:mm"),
      reserved: false,
      roomAvailable: roomAvailable,
      newNetPriceProvider: newNetPriceProvider,
      newNetPriceUsblick: newNetPriceUsblick,
      newNetPriceAgent: newNetPriceAgent,
      newBlickCoins: newBlickCoins,
      newCreditBlickoins: newCreditBlickoins,
      newBlickoinsFactor: newBlickoinsFactor,
      newCreditFactor: newCreditFactor,
      newRoomType: newRoomType,
      newDescripton: newDescription,
    };
  }

  activePartial() {
    console.log("activo partial this.id", this.id);
    //siempre mandar a limpiar todos los items
    // this.itineraryService.updateRemoveItineraryPartial(this.id);
    if (this.isActivePartialReserve == true) {
      this.userService.isActivePartial = false;
      this.isActivePartialReserve = false;
      this.qtyItemsToPartial = 0;
      this.userService.totalPriceNotFeeByReserved = 0;
      this.userService.totalPriceYesFeeByReserved = 0;
      this.itineraryService.parcialBlickoins = 0;
    } else {
      this.isActivePartialReserve = true;
      this.userService.isActivePartial = true;
    }
  }

  _itemToPartial(qty) {
    console.log("recibo itineray", qty);
    this.qtyItemsToPartial = this.qtyItemsToPartial + qty;
  }

  openNR() {
    var dialogRef = this.dialog.open(NrPrepaymentModalComponent, {
      width: "820px",
      height: "700px",
      panelClass: "background-modal",
      //disableClose: true
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed", result);

      if (result) {
        this.reserveAll();
      } else {
      }
    });
  }

  openNRNotAvailable() {
    var dialogRef = this.dialog.open(NrBlockModalComponent, {
      width: "770px",
      height: "600px",
      panelClass: "background-modal",
      //disableClose: true
    });
  }

  openModalNR() {
    let totalprice = 0;
    let totalpriceNR = 0;

    console.log("this.userService.itineraryActive", this.userService.itineraryActive);

    this.userService.itineraryActive[0].items.forEach((element) => {
      totalprice = totalprice + element.netPriceBooking;
      if (element.nr) {
        totalpriceNR = element.netPriceBooking + totalpriceNR;
      }
    });

    // console.log("total price all products", totalprice);
    // console.log("total price NR products", totalpriceNR);
    // console.log("this.fidelityService.creditPointActives", this.fidelityService.creditPointActives);
    // console.log("this.fidelityService.creditDepositActives", this.fidelityService.creditDepositActives);

    let agency = JSON.parse(localStorage.getItem("selectedAgency"));
    console.log("agency.pay_mode", agency.pay_mode);

    switch (agency.pay_mode) {
      case 1:
        console.log("caso 1");
        if (this.fidelityService.creditPointActives >= totalpriceNR) {
          console.log("tengo para pagar con puntos");
          //si tengo para pagar con puntos
          this.openNR();
          break;
        } else {
          //si tengo para pagar con puntos
          //uso this.openNRNotAvailable();
          console.log("No tengo para pagar con puntos");
          this.openNRNotAvailable();
          break;
        }

      case 2:
        console.log("caso 2");
        this.openNR();
        break;

      case 3:
        console.log("caso 3");
        if (
          this.fidelityService.creditPointActives >= totalpriceNR ||
          this.fidelityService.creditDepositActives >= totalpriceNR ||
          this.fidelityService.creditPointActives + this.fidelityService.creditDepositActives >= totalpriceNR
        ) {
          this.openNR();
          console.log("tengo para pagar con puntos");
        } else {
          console.log("No tengo para pagar con puntos");
          this.openNRNotAvailable();
        }

        break;

      case 4:
        console.log("caso 4");
        this.openNR();
        console.log("tengo para pagar");
        break;
      case 5:
        console.log("caso 5");

        this.openNR();
        console.log("tengo para pagar");

        break;

      case 6:
        console.log("caso 6");

        this.openNR();
        console.log("tengo para pagar");

        break;
      default:
        break;
    }
  }

  updateNRProduct(dato: any) {
    let date = dato.expirationDate ? moment(new Date()) : null;
    if (date) {
      if (date > moment(dato.expirationDate).subtract(2, "days")) {
        console.log("this.itinerary", this.itinerary);
        this.itinerary.items.forEach((element) => {
          if (dato.id == element.id) {
            console.log("consegui item en itineraio");
            element.nr = true;
            console.log(" this.itinerary update con NR", this.itinerary);
            this.itineraryService.updateItinerary(this.itinerary, false);
            return;
          }
        });
      }
    }
  }

  pay() {
    var dialogRef = this.dialog.open(ModalPostBookingComponent, {
      width: "899px",
      height: "80vh",
      data: this.itinerary,
      //disableClose: true
    });

    dialogRef.afterClosed().subscribe((result) => {
      //console.log('The dialog was closed', result);
      if (result) {
        this.itineraryService.consultActiveItinerary(this.userService._agentID);
        this.getItinerary("false");
      } else {
      }
    });
  }

  searchProductReserved() {
    let result = this.itinerary.items.filter((element) => element.status_id == 2);
    //console.log("existe items con status id 2 ", result);
    if (result.length > 0) {
      let i = 0;
      result.forEach((element) => {
        if (
          element.isPendigApprovePay === false &&
          element.isPaidAgency === false &&
          element.isPaidProvider === false &&
          element.isBilled === false
        ) {
          i++;
        }
      });

      if (i > 0) {
        return true;
      } else {
        return false;
      }
    }
  }
}
