<div class="row"
      usbLoadingSplash
      [appLoading]="appLoading"
      [fullscreen]="true"
>


  <div class="col-8">
    <div *ngFor="let items of itineraryItems | groupBy: 'categoryName'; let i = index">
      <div *ngFor="let item of items.value | orderBy: 'title'; let i = index">
        <div *ngIf="i==0 || (itineraryItems[i-0]!=undefined && (itineraryItems[i-0].categoryName!=item.categoryName))">
          <span class="title text-uppercase d-flex align-items-center" *ngIf="item.categoryName=='HOTELS'">
            <span class="circle-icon mr-3" style="color: #000000">
              <i class="icons-hotel"></i>
            </span> Selecciona habitación para pasajeros
          </span>
          <span class="title text-uppercase" *ngIf="item.categoryName=='PTHEMES'"><i
              class="icon-small icon-pthemes iconstart  text-center pr-3"></i>Select ptheme for passengers</span>
          <span class="title text-uppercase d-flex align-items-center" *ngIf="item.categoryName=='TRANSPORTS'">
            <span class="circle-icon mr-3" style="color: #000000">
              <i class="icons-transport"></i>
            </span>
            Select transport for passengers</span>
          <span class="title text-uppercase" *ngIf="item.categoryName=='DISNEY'"><i
              class="icon-small icon-disney iconstart  text-center pr-3"></i>Select disney for passengers</span>
          <span class="title text-uppercase d-flex align-items-center" *ngIf="item.categoryName=='RENT-A-CAR'">
            <span class="circle-icon mr-3">
              <i class="icons-cars"></i>
            </span>
            Seleccionar coches para pasajeros</span>
          <span class="title text-uppercase d-flex align-items-center" *ngIf="item.categoryName=='TOURS'">
            <span class="circle-icon mr-3">
              <i class="icons-tours"></i>
            </span>
            Select tours for passengers</span>
          <span class="title text-uppercase" *ngIf="item.categoryName=='INSURANCE'"><i
              class="icon-small icon-seguro iconstart  text-center pr-3"></i>Select insurance for passengers</span>
          <span class="title text-uppercase" *ngIf="item.categoryName=='CRUISES'"><i
              class="icon-small icon-crucero iconstart  text-center pr-3"></i>Select cabin for passengers</span>
        </div>
        <accordion [closeOthers]="true" class="mb-4">
          <accordion-group [isOpen]="i == 0 ? true : false">
            <div class="row" accordion-heading>
              <div class="col-12 d-flex justify-content-between">
                <div class="d-flex flex-column p-3 product-select-data"
                [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_2}">
                  <div class="name-hotel">
                    <div class="pull-left title-producto" *ngIf="item.categoryName=='HOTELS'">
                      <object type="image/svg+xml" data='/assets/img/app/little-icons/placeholder_verd.svg' width="15px"
                        height="15px" style="margin-right: 8px"></object>
                      {{item.payloadUpdate.products[0].destination}} > <span class="name"
                      [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}">{{item.title | truncate : 40 : "  "}}</span>
                    </div>
                    <div class="pull-left title-producto" style="color:#00d397" *ngIf="item.categoryName=='PTHEMES'">
                      <object type="image/svg+xml" data='/assets/img/app/little-icons/placeholder_verd.svg' width="15px"
                        height="15px"></object>
                      {{item.payloadUpdate.products[0].destination}} > <span class="name" style="color:#00d397">Activity
                        {{i+1}}</span> </div>
                    <div class="pull-left title-producto" *ngIf="item.categoryName=='TRANSPORTS'">
                      <object type="image/svg+xml" data='/assets/img/app/little-icons/placeholder_verd.svg' width="15px"
                        height="15px"></object>
                      {{item.payloadUpdate?.products[0]?.destination}} >
                      <span class="name">
                        Vehicle {{i+1}}
                      </span>
                    </div>
                    <div class="pull-left title-producto" *ngIf="item.categoryName=='DISNEY'">
                      <object type="image/svg+xml" data='/assets/img/app/little-icons/placeholder_verd.svg' width="15px"
                        height="15px"></object>
                      Disney -
                      <span class="name" style="color:#00d397">{{item.hotel.title | truncate : 40 : "  "}}
                        {{i+1}}</span></div>
                    <div class="pull-left title-producto" *ngIf="item.categoryName=='RENT-A-CAR'">
                      <object type="image/svg+xml" data='/assets/img/app/little-icons/placeholder_verd.svg' width="15px"
                        height="15px"></object>
                      {{item.payloadUpdate.products[0].destination}} > <span class="name"
                        style="color:#00d397">{{item.title | truncate : 40 : "  "}} {{i+1}}</span>
                    </div>
                    <div class="pull-left title-producto" *ngIf="item.categoryName=='INSURANCE'">
                      <object type="image/svg+xml" data='/assets/img/app/little-icons/placeholder_verd.svg' width="15px"
                        height="15px"></object>
                      {{item.payloadUpdate.products[0].destination}} > <span class="name" style="color:#00d397">Insurance
                        {{i+1}}</span></div>
                    <div class="pull-left title-producto" *ngIf="item.categoryName=='CRUISES'">
                      <object type="image/svg+xml" data='/assets/img/app/little-icons/placeholder_verd.svg' width="15px"
                        height="15px"></object>
                      {{item.payloadUpdate.products[0].destination}} > <span class="name" style="color:#00d397"> Cruise
                        {{i+1}}
                      </span></div>
                    <div class="pull-left title-producto" *ngIf="item.categoryName=='TOURS'">
                      <object type="image/svg+xml" data='/assets/img/app/little-icons/placeholder_verd.svg' width="15px"
                        height="15px"></object>
                      {{item.payloadUpdate.products[0].destination}} > <span class="name" style="color:#00d397">
                        {{item.title | truncate : 40 : "  "}} - Ticket
                        {{i+1}}</span>
                    </div>
                    <span class="pull-right" style="display: flex; justify-content: space-between; align-items: center;">
                      <p  style="font-size: 14px; font-weight:600; text-transform: uppercase;margin-bottom: 0;">{{item.roomType}}</p>
                      <i class="icon-down ml-3" style="margin-top: .1em"></i>
                    </span>
                  </div>
                  <div class="" style="margin-left:1.6em;font-size: small;">
                    <!-- <p class="m-0 text-uppercase font-detail" *ngIf="item.categoryName=='HOTELS'">
                      {{item.roomType}}</p> -->
                    <p class="mt-1 font-detail">
                      <span>CHECK-IN: {{item.arrivalDate !== 'To be defined'? (item.arrivalDate | date: 'dd MMM YY') : item.arrivalDate}} </span>
                        <span class="ml-2 mr-2">|</span>
                      <span *ngIf="item.categoryName!='TOURS'"> CHECK-OUT: {{item.departureDate !== 'To be defined'? (item.departureDate | date: 'dd MMM YY') : item.departureDate}}
                      </span>
                    </p>
                    <div *ngIf="item.categoryName !== 'RENT-A-CAR'" class="mt-1 m-0 font-detail-2 d-flex align-items-end">
                      <i class="icono-night mr-1"></i>
                      {{item.nights}} noches
                      <i class="icono-adult ml-3 mr-1"></i>
                      {{item.paxAdults + (item.paxAdults > 1 ? ' Adultos' : ' Adulto')}}
                      <i class="icono-child ml-3 mr-1"></i>
                      {{item.paxChildren +  ' Niños'}}
                    </div>
                    <p class="mt-1 m-0 font-detail-2 d-flex align-items-end">
                      <span
                            *ngIf="checkProductConfirm(item)"
                            [ngClass]="{'status-ok': checkProductConfirm(item), 'status-pending': !checkProductConfirm(item)}"
                            style="position: absolute; right: 3em;">Listo</span>
                      <span *ngIf="!checkProductConfirm(item)"
                            [ngClass]="{'status-ok': checkProductConfirm(item), 'status-pending': !checkProductConfirm(item)}"
                            style="position: absolute; right: 3em;">Pendiente</span>
                    </p>
                  </div>
                </div>
                <!-- <div class="d-flex justify-content-between p-3 product-status"
                  [ngClass]="{'status-ok': checkProductConfirm(item), 'status-pending': !checkProductConfirm(item)}">
                  <object *ngIf="!checkProductConfirm(item)" type="image/svg+xml" data='/assets/img/app/little-icons/timer_bco.svg'
                    width="30px" height="30px"></object>
                  <span *ngIf="checkProductConfirm(item)" class="icon-check"></span>
                  <span class="text-white" *ngIf="checkProductConfirm(item)">Listo</span>
                  <span class="text-white" *ngIf="!checkProductConfirm(item)">Pendiente</span>
                </div> -->
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <form (ngSubmit)="_onSubmit()" [formGroup]="_form" novalidate>
                  <div class="row px-3">
                    <div class="px-0"
                      [ngClass]="{'col-3' : item.categoryName=='TRANSPORTS' || item.categoryName=='RENT-A-CAR', 'col-4' : item.categoryName !='TRANSPORTS' && item.categoryName !='RENT-A-CAR'}">
                      <div class="form-group">
                        <label *ngIf="item.categoryName=='RENT-A-CAR'">Conductor</label>
                        <label *ngIf="item.categoryName!=='RENT-A-CAR'">Pasajeros</label>
                        <select class="form-control" #pax (change)="setPaxToItem(pax.value, item, $event, i);">
                          <option disabled value="null">Seleccione los pasajeros</option>
                          <ng-container *ngIf="item.paxChildren === 0; else showOptions">
                            <!-- Si item.paxChildren es igual a 0 y pax.relation_ship es "Adult", muestra las opciones de adultos -->
                            <option *ngFor="let pax of paxAdultsArr; let i = index;"
                                    [value]="pax.document_number"
                                    [attr.data-idUnique]="pax.idUnique"
                                    [attr.data-name]="pax.first_name"
                                    [attr.data-last_name]="pax.last_name"
                                    [attr.data-relation_ship]="pax.relation_ship"
                                    [attr.data-gender]="pax.gender"
                                    [attr.data-birth_date]="pax.birth_date"
                                    [attr.data-title]="pax.title"
                                    [attr.data-document_type_id]="pax.document_type_id"
                                    [attr.data-address]="pax.address"
                                    [attr.data-country_id]="pax.country_id"
                                    [attr.data-city]="pax.city"
                                    [attr.data-phone]="pax.phone"
                                    [attr.data-phone_2]="pax.phone_2"
                                    [attr.data-client_id]="pax.clientId"
                                    [attr.data-state_id]="pax.state_id"
                                    [attr.data.type]="pax.type">
                              {{pax.first_name}} {{pax.last_name}} - Adulto
                            </option>
                          </ng-container>
                          <ng-template #showOptions>
                            <!-- Si item.paxChildren no es igual a 0 o pax.relation_ship no es "Adult", muestra las opciones de paxes como de costumbre -->
                            <option *ngFor="let pax of paxes; let i = index;"
                                    [value]="pax.document_number"
                                    [attr.data-idUnique]="pax.idUnique"
                                    [attr.data-name]="pax.first_name"
                                    [attr.data-last_name]="pax.last_name"
                                    [attr.data-gender]="pax.gender"
                                    [attr.data-relation_ship_catalogue_id]="pax.relation_ship_catalogue_id"
                                    [attr.data-birth_date]="pax.birth_date"
                                    [attr.data-title]="pax.title"
                                    [attr.data-document_type_id]="pax.document_type_id"
                                    [attr.data-address]="pax.address"
                                    [attr.data-country_id]="pax.country_id"
                                    [attr.data-phone]="pax.phone"
                                    [attr.data-phone_2]="pax.phone_2"
                                    [attr.data-city]="pax.city"
                                    [attr.data-client_id]="pax.clientId"
                                    [attr.data-state_id]="pax.state_id"
                                    [attr.data-type]="pax.type">
                              {{pax.first_name}} {{pax.last_name}} - {{pax.type == 'children' ? 'Niño' : 'Adulto'}}
                            </option>
                          </ng-template>
                        </select>
                      </div>
                    </div>
                    <div class="col-4 px-1" *ngIf="item.categoryName=='TOURS'">
                      <div class="form-group">
                        <label for="Hotel">Seleccione Hotel</label>
                        <input type="text" class="form-control" id="{{'hotel-' +item.id}}" [value]="namehotel"
                          (keyup)="_permitedReservation()">
                      </div>
                    </div>
                    <div class="col-4 px-0" *ngIf="item.categoryName=='TOURS'">
                      <div class="form-group">
                        <label for="checkin">Chech IN</label>
                        <input type="text" class="form-control" id="checkin" readonly="readonly"
                          [value]="formatDate(item.arrivalDate)  | uppercase">
                      </div>
                    </div>
                    <!-- <div class="col-9 px-1" *ngIf="item.categoryName=='TRANSPORTS' || item.categoryName=='RENT-A-CAR'">
                      <div class="form-group">
                        <label for="transport">Info</label>
                        <input type="text" placeholder="" class="form-control" formControlName="idComment" id="{{'idComment-' +item.id}}"
                          (change)="_permitedReservation()">
                      </div>
                    </div> -->
                  </div>
                </form>
              </div>
              <div class="col-12 mb-4">
                <!-- [ngClass]="{ 'justify-content-between' : (paxesInItems.length > 2), 'justify-content-between' : (paxesInItems.length < 3) } -->
                <div class="row px-3 " >
                  <ng-container *ngFor="let pax of paxesInItems; let i = index;">
                    <!-- pax-item -->
                    <div *ngIf="pax.itemId==item.id" class="d-flex justify-content-space-between mb-2"
                      [ngStyle]="{'margin-right' : (paxesInItems.length < 3) ? '10px' : '0px'}" style="width: 33.33%">
                      <div class="pax-name">
                        <div class="btn d-flex align-items-center" style="background-color: #afeedd;border-radius: 5px; height: 44px">
                          <input type="hidden" id="idpax" [value]="item.id" />
                          <div *ngIf="pax.itemId==item.id" class="">
                              <div class="d-flex align-items-center">
                                <i *ngIf="pax.type === 'CH'" class="icon-child-gray mr-2"></i>
                                <i *ngIf="pax.type !== 'CH'" class="icon-man-gray mr-2"></i>
                                <label style="align-items: center; margin-top: 9px;"> {{pax.first_name}} {{pax.last_name}}</label>
                              </div>
                          </div>
                          <div *ngIf="item.categoryName=='TOURS'">
                            <input type="hidden" id="{{'idPax-' +pax.idPax}}" [value]="pax.idPax">
                          </div>
                        </div>
                      </div>

                      <div class="delete">
                        <a (click)="removePax(pax.idUnique, pax.itemId, pax)" class="btn ml-1"
                          style="cursor:pointer; background-color: white; border: solid 1px #e0e0e0;;height:44px;width:44px">
                          <i class="icon-cesto"></i>
                        </a>
                      </div>
                      <div *ngIf="item?.additionalDriver" class="d-flex">
                        <span>
                          <label class="custom-radio-checkbox mb-0 mt-2">
                            <input [(ngModel)]="selectedValue" class="custom-radio-checkbox__input" id="{{pax.idPax}}" value="{{pax.idPax}}"
                              type="radio" (click)="additionalDriver(pax.document_number, pax.idPax)">
                            <span class="custom-radio-checkbox__show custom-radio-checkbox__show--radio"></span>
                          </label>
                        </span>
                        <label class="pt-1" style="line-height: 15px !important; width: 72px;">Conductor Adicional</label>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="col-12 ml-1 mb-5">
                <div class="d-flex flex-column">
                  <!-- <div class="d-flex flex-column mb-2">
                    <p class="font-weight-bold text-uppercase mb-1">Observaciones</p>
                    <p style="font-size: 14px">Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis sunt laborum amet, quaerat qui vel libero numquam consequatur fugit id laudantium exercitationem enim delectus ut architecto similique corrupti commodi tempora.</p>
                  </div> -->
                  <div class="d-flex flex-column">
                    <p class="font-weight-bold mb-2"
                       style="font-family: 'Poppins', sans-serif;font-size: 14px"
                    >
                      Peticiones al establecimiento (solo indicativas)</p>
                    <div class="mb-4 row" *ngIf="item.categoryName == 'HOTELS'">
                      <span *ngFor="let remark of remarks"
                            class="col-lg-3 col-md-2 d-flex align-items-center mb-2"
                      >
                          <input
                                class="mr-2"
                                type="checkbox"
                                (change)="requestRoomHotel(item, remark.name)"

                          />
                          <p style="font-size: 12px;margin-bottom: 0px;">{{remark.name}}</p>
                      </span>
                    </div>
                  </div>
                    <textarea class="textarea-comments"
                              name=""
                              id=""
                              cols="85"
                              rows="5"
                              placeholder="Comentarios"
                              (input)="requestCommentHotel(item, $event.target.value)"
                    ></textarea>
                    <p class="mt-2 mx-2" style="font-size: 12px;margin-bottom: 0px;">Los requerimientos son enviados al hotel junto con la reserva.  Ninguno es considerado una confirmación y cada uno esta sujetos a confirmación y aprobación por parte del hotel.</p>
                </div>
              </div>
            </div>
          </accordion-group>
        </accordion>
      </div>
    </div>
  </div>
  <div class="col-4">
    <span class="mb-3 px-2" style="font-size: 1.65rem;" [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}">SUMMARY</span>
    <div class="sidebar p-2 pb-5">
      <usb-summary-reservation [itineraryItems]="itineraryItems" [itineraryTotal]="itineraryTotal" [itinerary]="itinerary">
      </usb-summary-reservation>
      <!-- <div class="d-flex"> -->
        <button (click)="_onSubmit();" [disabled]='permitedReservation==false || reserving' class="btn btn-block"
          style="font-family: 'Poppins';font-size: 19px; font-weight: 500;border-radius: 50rem; cursor: pointer; height: 70px;" [ngStyle]="{'background':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}">
          RESERVAR
        </button>
        <span style="font-size: 0.8em; margin-left: 15px; opacity: 0.5;">Regresar para modificar los datos del formulario</span>
        <button (click)="_return()" class="btn btn-block" style="height: 45px;border-radius: 50rem; cursor: pointer; background-color: #afeedd">
          <span>REGRESAR</span>
        </button>
      <!-- </div> -->

    </div>
  </div>
</div>

<usb-itinerary-detail style="display:none"
                      #itineraryDetail
                      [appLoading]="appLoading"
                      [results]="results"
                      [itemsSync]="itemsSync"
                      [itineraryId]="itineraryId"
                      (shouldContinueEvent)="shouldContinue($event)">
</usb-itinerary-detail>
