import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef } from "@angular/core";
import { ItineraryService } from "../../../shared/itinerary.service";
import { Router, NavigationExtras, ActivatedRoute } from "../../../../../node_modules/@angular/router";
import * as moment from "moment";
import { DatePipe } from "@angular/common";
import { ActivitiesComponent } from "../../../home/search-box/categories/activities/activities.component";
import { HotelsComponent } from "../../../home/search-box/categories/hotels/hotels.component";
import { PthemesComponent } from "../../../home/search-box/categories/pthemes/pthemes.component";
import { TransportsComponent } from "../../../home/search-box/categories/transports/transports.component";
import { RentcarsComponent } from "../../../home/search-box/categories/rentcars/rentcars.component";
import { InsuranceComponent } from "../../../home/search-box/categories/insurance/insurance.component";
import { DisneyComponent } from "../../../home/search-box/categories/disney/disney.component";
import { element } from "protractor";
import { RestService } from "../../../shared/rest.service";
import { ModalDirective, BsModalService, BsModalRef, ModalOptions } from "ngx-bootstrap";
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from "@angular/forms";
import { ResultsSearchService } from "../../../home/results-search.service";
import { UserService } from "../../../pages/com.usblick.common/auth/user.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Observable } from "rxjs";
import { FidelityService } from "../../../fidelity/fidelity.service";
import { ModalsComponent } from "../../../shared/modals/modals.component";
import { MatDialog } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { MaterialModalConfirmComponent } from "../../../shared/material-modal-confirm/material-modal-confirm.component";
import { MaterialModalAlertComponent } from "../../../shared/material-modal-alert/material-modal-alert.component";

@Component({
  selector: "usb-item-status",
  templateUrl: "./item-status.component.html",
  styleUrls: ["./item-status.component.scss"],
  providers: [DatePipe],
})
export class ItemStatusComponent implements OnInit {
  @ViewChild("modalRooms") modalRooms: ModalDirective;

  opt: any;
  @Input() status: string;
  @Input() reservation: any;
  @Input() selected: boolean;
  @Input()  item: any;
  @Input() modifications: boolean;
  @Input() showTextChangeDetailsProducts: boolean;
  @Output() reserve = new EventEmitter<any>();
  @Output() update = new EventEmitter<string>();
  @Output() delete = new EventEmitter<string>();
  @Output() accept = new EventEmitter<string>();
  @Output() reSearch = new EventEmitter<any>();
  @Output() reloadItinerary = new EventEmitter<any>();
  @Input() changePriceFromReserve: boolean;

  @Output() itemToPartial = new EventEmitter<number>();

  @Input() partialReserve: boolean;

  _form: FormGroup;

  modalRef: BsModalRef;
  percentage: number = 0;
  blickoins: any;
  screen: string;
  fee: number = 0;
  totalPrice: number;
  netPriceBooking: number;
  optionNightsNetTotalFreeTax: number;
  taxNightsPriceTotal: number;
  taxPrice: number;
  netPriceAgent: number;
  itineraryDetail: any;
  itinerary: any;
  reservationId: number;
  addFee: any = "";
  activeGlobalFee: any;
  prices$: Observable<any[]>;
  globalFee$: Observable<any[]>;
  totalpartialNofee: number = 0;
  totalpartialYesfee: number = 0;
  showOthersItem: any;
  ModalRooms: any;
  newPrice: boolean = true;
  payload: any;
  result: any;
  arrayResults: any;
  index: number;
  form: FormGroup;
  origin: string;
  modal: boolean = false;
  public taxSum: number = 0;
  mapView: Boolean = false;
  addIcon = "favorite_border";
  netPriceProf = 0;
  public taxes: any[] = [];
  optionNightsNetTotal: number = 0;
  optionNightsNetTotalProf: number = 0;
  netPriceBookingFreeTax: number = 0;

  bsModalRef?: BsModalRef;

  creditBlickoins = 0;
  view_hotel = 0;
  isview_hotel = false;
  ameninitiesResul = [];
  booleanAlert:boolean = true;
  currencyItinerary: string;

  constructor(
    private datePipe: DatePipe,
    private itineraryService: ItineraryService,
    private route: ActivatedRoute,
    private router: Router,
    private restService: RestService,
    private fb: FormBuilder,
    private _restService: RestService,
    private resultsSearch: ResultsSearchService,
    public userService: UserService,
    private spinner: NgxSpinnerService,
    private modalService: BsModalService,
    public fidelityService: FidelityService,
    private dialog: MatDialog,
    private toastr: ToastrService
  ) {
    this.opt = {
      acceptNewPrice: false,
      acceptOthersItem: false,
      acceptOthersSearch: false,
      search: false,
      delete: false,
      reserve: false,
    };
  }

  ngOnInit() {
console.log(this.item)
    if(this.changePriceFromReserve) {
      this.showTextChangeDetailsProducts = true;
    }

    this.showOthersItem = false;
    this.selected = true;
    this.route.queryParams.subscribe((params) => {
      this.screen = params["screen"];
      if (this.screen == "reservation") {
        this.reservationId = params["reservationId"];
      }
      if (params["process"]) {
        this.addFee = params["process"];
      }
    });

    console.log("item", this.item);
    console.log(this.item.item?.profility?.blickoins)
    // console.log("modifications", this.modifications);
    // console.log('reservation -->', this.item.item.updateDate);


    this.item.product = {};
    this.taxNightsPriceTotal = this.item.item.taxNightsPriceTotal;
    this.taxPrice = this.item.item.taxPrice;
    this.optionNightsNetTotalFreeTax = this.item.item.optionNightsNetTotalFreeTax;
    this.optionNightsNetTotal = this.item.item.optionNightsNetTotal;
    this.optionNightsNetTotalProf = this.item.item.optionNightsNetTotalProf;
    this.netPriceBooking = this.item.item.netPriceBooking;
    this.netPriceAgent = this.item.item.netPriceAgent;
    this.netPriceBookingFreeTax = this.item.item.netPriceBookingFreeTax;
    this.creditBlickoins = this.item.item?.profility?.creditBlickoins;
    this.blickoins = this.item.item?.profility?.blickoins;
    this.percentage = this.item.item.percentageAgent;
    this.prices$ = this.userService.getPrices$();
    this.activeGlobalFee = this.userService.activeGlobalFee;
    this.currencyItinerary = this.item.item.currency;
    this.globalFee$ = this.userService.getGlobalFee$();
    this.globalFee$.subscribe((data: any) => {
      this.userService.itinerayDetailFee.products.forEach((element) => {
        if (element.item.id == this.item.id) {
          this.percentage = data;
          // this.getFee();
        }
      });
      this.getFee();
    });
    /// this.getProduct();

    this.showPriceCancelled();
    // Calcula la suma de los montos de impuestos incluidos
    this.taxSum = this.item.item?.room?.pricing?.taxes.reduce((total, tax) => {
      // Verifica si el valor de 'included' es un booleano
      if (typeof tax.included === 'boolean' && tax.included) {
        this.taxes.push(tax);
        // Si el impuesto está incluido y 'included' es un booleano verdadero, sumarlo al total
        return total + tax.amount;
      } else {
        return total;
      }
    }, 0); // Inicializa el total en 0
    // Formatea el resultado con dos decimales
    this.taxSum = parseFloat(this.taxSum.toFixed(2));


  }


  calSync(date: any){
    /*let date_ =  new Date(date);
      if (date_) {
        console.log("llegando fecha: ", date_);
        return date_.setMinutes(date_.getMinutes() - 10);
      }*/
  }

  _showOthersItem() {
    if (this.showOthersItem) {
      this.showOthersItem = false;
    } else {
      this.showOthersItem = true;
    }
  }

  getProduct() {
    if (this.item.item.payloadUpdate) {
      this.restService.doPost("app", "search/products", this.item.item.payloadUpdate).subscribe(
        (response: any) => {
          if (response && response.status && response.results) {
            if (response.results[0] !== undefined) {
              this.item.product = response.results[0];
            } else {
              this.item.product = response.results;
            }
          }
        },
        (error) => {
          console.log(error.message);
        }
      );
    }
  }

  getExpirationDate(date: any) {
    // return this.datePipe.transform(moment(this.reservation.resultReserve.additionalInfo.payBefore), 'MMM dd, yyyy')
    //  return this.datePipe.transform(date, 'dd/mm/yyyy h:m');
    const dateOut = new Date(date);
    return dateOut;
  }
  updateItem() {
    this.update.emit("non-updated");
  }
  deleteItem() {
    this.opt = {
      accept: false,
      search: false,
      delete: true,
      reserve: false,
    };
    this.itineraryService.remove(this.item.item !== undefined ? this.item.item : this.item);
    setTimeout(() => {
      this.delete.emit(this.item.item.id);
      window.location.reload();
    }, 500);
  }
  deleteAndSearch() {
    this.opt = {
      accept: false,
      search: true,
      delete: false,
      reserve: false,
    };
    this.itineraryService.remove(this.item.item !== undefined ? this.item.item : this.item);

    setTimeout(() => {
      switch (this.item.item.categoryName) {
        case "HOTELS":
          let hotel: HotelsComponent;
          hotel._onSubmit(this.item.item.form);
          break;
        case "PTHEMES":
          let ptheme: PthemesComponent;
          ptheme._onSubmit(this.item.item.form);
          break;
        case "TRANSPORTS":
          let transport: TransportsComponent;
          transport._onSubmit(this.item.item.form);
          break;
        case "RENT-A-CAR":
          let car: RentcarsComponent;
          car._onSubmit(this.item.item.form);
          break;
        case "INSURANCE":
          let insurance: InsuranceComponent;
          insurance._onSubmit(this.item.item.form);
          break;
        case "DISNEY":
          let disney: DisneyComponent;
          disney._onSubmit(this.item.item.form);
          break;
        case "TOURS":
          let tour: ActivitiesComponent;
          tour._onSubmit(this.item.item.form);
          break;

        default:
          break;
      }
    }, 1500);
  }

  acceptNewPrice() {
    const data = this.item.result;
    const item = this.item.item;
    console.log("this.item acceptNewPrice", this.item);
    const initialState: ModalOptions = {
      initialState: {
        message:`¿Deseas aceptar nuevo precio? ${this.item?.item?.currency} ${item.syncProduct?.newPrice?.profility?.netPriceBooking}`,
      }
    };
    this.bsModalRef = this.modalService.show(MaterialModalConfirmComponent, initialState);
    const confirmSubscription = this.bsModalRef.content.confirmResult.subscribe((result: boolean) => {
      if(result) {

        /*this.item.item.refPrice = item.syncProduct?.newNetPriceProvider;
        this.item.item.netPriceProvider = item.syncProduct?.newNetPriceBooking;
        this.item.item.netPriceBooking = item.syncProduct?.newNetPriceBooking;
        this.item.item.netPriceAgent =  item.syncProduct?.newNetPriceAgent;
        this.item.item.blickoins = item.syncProduct?.newBlickCoins;*/

        this.item.item.refPrice =  item.syncProduct?.newPrice?.profility?.netPriceProvider;
        this.item.item.netPriceProvider = item.syncProduct?.newPrice?.profility?.netPriceProvider;
        this.item.item.netPriceBooking = item.syncProduct?.newPrice?.profility?.netPriceBooking;
        this.item.item.netPriceAgent =  item.syncProduct?.newPrice?.profility?.netPriceAgent;
        this.item.item.blickoins = item.syncProduct?.newPrice?.profility?.blickoins;

        this.accept.emit();
        setTimeout(() => {
          console.log("mandas actualizar nuevo precio", this.item)
          this.userService.postNewPrice(this.item);
        }, 500);
      } else {
        this.opt.acceptNewPrice = false;
      }

    });
    this.booleanAlert = false;
  }

  openModal_(tab: any) {
    this.modal = true;
    this.updteView();

    if (localStorage.getItem('form')) {
      localStorage.removeItem('form');
    }

    const dialogRef = this.dialog.open(ModalsComponent, {
      height: '680px',
      width: '920px',
      data: {
        modalRoomRef: this.modalRef,
        product: this.item.result.results[0],
        productQty: 1, /// this.payload.qtyProduct,
        form:  this.item.item.form,
        origin: 'HOTELS',
        arrayResults: this.item.result.results[0].additionalInfo.room,
        tabActive: tab,
        view_info: false
      },
    });

    dialogRef.afterClosed().subscribe((result) => {

      this.reloadItinerary.emit();

      if (result) {
      ///  this.reloadItinerary.emit();
      ///  this.itineraryService.remove(this.item.item !== undefined ? this.item.item : this.item.item);

    /*    var dialogRef = this.dialog.open(MaterialModalAlertComponent, {
          height: "auto",
          width: "350px",
          data: "producto Agreado satisfactoriamente"
        });
        dialogRef.afterClosed().subscribe(result_ => {
          if (result_) {
            console.log("emitir actualizar");
            this.reloadItinerary.emit();
          }
        }); */
      }
    });
  }


  openModal() {
    var dialogRef = this.dialog.open(ModalsComponent, {
      height: '680px',
      width: '800px',
      data: {
        modalRoomRef: this.modalRef,
        product: this.item.product,
        productQty: "1",
        //form: this.activeItineraryForm,
        origin: 'HOTELS',
        itineraryItem: this.item.item,
        view_info: true,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
    });

  }

  updteView() {
    let data = "";
    if (!this.isview_hotel) {
      this._restService.doPut("app", "products/updateView/" + this.item.result.results[0].id, data).subscribe(
        (response: any) => {
          console.log(response)
          if (response.success) {
            this.view_hotel = response.res.qtyview;
            this.isview_hotel = true;
          }
        },
        (error) => {
          console.log("Save-Error: " + error);
        }
      );
    }
  }

  acceptOthersOtherItem() {
    const initialState: ModalOptions = {
      initialState: {
        message: '¿Desea eliminar este producto y ver otras opciones?'
      }
    };
    this.bsModalRef = this.modalService.show(MaterialModalConfirmComponent, initialState);
    const confirmSubscription = this.bsModalRef.content.confirmResult.subscribe((result: boolean) => {
      if(result) {
        if (result) {
          this.itineraryService.remove(this.item.item !== undefined ? this.item.item : this.item.item);
         setTimeout(() => {
            this.openModal_('rooms');
          }, 1000);
        } else {
          this.openModal_('rooms');
        }
      } else {

      }
      confirmSubscription.unsubscribe();
    });
  }

  acceptOthersSearch() {
    const initialState: ModalOptions = {
      initialState: {
        message: '¿Desea hacer una nueva búsqueda?'
      }
    };

    this.bsModalRef = this.modalService.show(MaterialModalConfirmComponent, initialState);
    const confirmSubscription = this.bsModalRef.content.confirmResult.subscribe((result: boolean) => {
      if (result) {
        this.reSearch.emit(this.item.item);
        // this.itineraryService.remove(this.item.item !== undefined ? this.item.item : this.item.item);
     } else {
       this.reSearch.emit(this.item.item);
     }
      confirmSubscription.unsubscribe();
    });
  }

  removeItem() {
    this.itineraryService.remove(this.item.item !== undefined ? this.item.item : this.item);
    setTimeout(() => {
      this.delete.emit(this.item.item.id);
    }, 500);
  }
  reserveItem() {
    this.opt = {
      accept: false,
      search: false,
      delete: false,
      reserve: true,
    };
    setTimeout(() => {
      this.reserve.emit();
      this.itineraryService.updateItineraryPartial(this.item.item);
    }, 50);
  }

  getClxDate(date: any) {
    return this.datePipe.transform(date, "MMM dd, yyyy");
  }

  updateItemsFee(e: any) {
    console.log(this.item)
    const percentage = e.target.value;
    console.log(this.item.item.netPriceBooking)
    const netPriceAgent = this.item.item.netPriceBooking / (1 - percentage / 100);
    console.log(netPriceAgent)
    this.fee = netPriceAgent - this.item.item.netPriceBooking;
    console.log('percentage:', percentage, 'netPriceAgent: ', netPriceAgent, 'fee: ', this.fee)
    this.userService.itinerayDetailFee.products.forEach((element) => {
      if (element.id == this.item.item.id && element.status_id == 1) {
        element.percentageAgent = percentage;
        element.netPriceAgent = netPriceAgent;
      }
    });
    this.netPriceAgent = netPriceAgent;
    setTimeout(() => {
      this.userService.updateProductsPrices(this.item.item.itinerary_id);
    }, 50);
  }

  getFee() {
    if (this.percentage < 0) {
      this.percentage = 0;
    }

    if (this.percentage != 0) {
      this.netPriceAgent = this.item.item.netPriceBooking / (1 - this.percentage / 100);
      this.fee = this.netPriceAgent - this.item.item.netPriceBooking;
    } else {
      this.fee = 0;
      this.netPriceAgent = this.item.item.netPriceBooking;
    }

    this.userService.itinerayDetailFee.products.forEach((element) => {
      if (element.id == this.item.item.id && element.status_id == 1) {
        element.percentageAgent = this.percentage;
        element.netPriceAgent = this.netPriceAgent;
      }
    });
    setTimeout(() => {
      this.userService.updateProductsPrices(this.item.item.itinerary_id);
    }, 50);
  }

  initPayload() {
    this._form = this.fb.group({
      destination: ["", Validators.compose([Validators.required])],
      arrivalDate: [null, Validators.compose([Validators.required])],
      departureDate: [null, Validators.compose([Validators.required])],
      room: this.fb.array([]),
      qtyProduct: [1, [Validators.required, Validators.min(1), Validators.max(7)]],
    });

    const room = this._form.get("room") as FormArray;
    room.push(
      this.fb.group({
        qtyAdults: [0, [Validators.required, Validators.min(1)]],
        qtyChilds: [0, Validators.required],
        childsage: this.fb.array([]),
      })
    );
  }

  _setArrivalAndDepartureDate(response: any, form) {
    response.results.forEach((obj) => {
      obj.dateIni = moment(form.value["arrivalDate"].toString().split(",")[0]).format("YYYY-MM-DD");
      obj.dateEnd = moment(form.value["departureDate"].toString().split(",")[0]).format("YYYY-MM-DD");
    });
  }

  toPartialReserve(event, item: any) {
    console.log(event)
    console.log(item)
    if (event.target.checked) {
      this.itineraryService.updateItineraryPartial(item.item);
      this.itemToPartial.emit(1);
    } else {
      this.itineraryService.updateItineraryPartial(item.item, true);
      this.itemToPartial.emit(-1);
    }
    setTimeout(() => {
      this.userService.reCalcularPrices(this.item.item.itinerary_id);
    }, 500);
  }



  subtractDays(date) {
    return moment(moment(date)).subtract(2, "days").format("YYYY-MM-DD");
  }


  fontPrice(price: number) {
    if (price > 1000) {
      const size = 20 - 1.5;
      return size + "px";
    }
    if (price > 10000) {
      const size = 20 - 2.5;
      return size + "px";
    }
    if (price > 100000) {
      const size = 20 - 3.5;
      return size + "px";
    }
  }

  showPriceCancelled():void{
    let valuePrice = this.item.item.status;

    if(valuePrice === 'Cancelado'){
      this.netPriceAgent = 0;
      this.netPriceBooking = 0;
    }
  }
}
