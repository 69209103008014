
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { AuthService } from "../pages/com.usblick.common/auth/auth.service";

import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { UsblickCommonEnum } from '../pages/com.usblick.common/usblick-common-enum';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { setting } from '../../../setting';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class RestService {

	numberAdultsObs$:BehaviorSubject<number> = new BehaviorSubject<number>(0);
	optionsTab$:BehaviorSubject<string> = new BehaviorSubject<string>('');
	timerObs$:BehaviorSubject<boolean> = new BehaviorSubject(false);


	public page = 1;

	private passengersData: any[] = null;
	private booleanTimer:boolean = false;

	getPassengersData():any{
		return this.passengersData;
	}

	setPassengersData(data:any):void{
		console.log('data set passengers',data);
		this.passengersData = data;
	}

	getBooleanTimer():any{
		return this.booleanTimer;
	}

	setBooleanTimer(data:boolean):void{
		console.log('booleantimer', data);
		this.booleanTimer = data;
	}

	public comeback : number = null;


	constructor(private http: HttpClient) {
		//
	}

	// Generic Get Request
	// tslint:disable-next-line:indent
	doGet(serve: string, endpoint: string): Observable<any> {
		let url = (serve == 'api') ? setting.apiOctopusUrl : setting.apiOctopusUrl;
		if (serve === 'app') {
			url = setting.apiBookingUrl;
		}
		return this.http
			.get(url + endpoint, { headers: (this._headers())})
		//	.map((response:any) => response)
			.catch(this.handleError);
	}

	// Generic Get Request
	doGet_(serve: string, endpoint: string): Observable<any> {
		let url = (serve == 'api') ? setting.apiOctopusUrl : setting.apiBookingUrl;
		if (serve === 'app') {
			url = setting.apiBookingUrl;
		}

  		//console.log('carga de datos GET', url + endpoint);

		return this.http
			.get(url + endpoint, { headers: (this._headers())})
			.map((response:any) => response)
			.catch(this.handleError);
	}

	// Generic Post Request
	doPost(serve: string, endpoint: string, param: any): Observable<any> {
    console.log(param)
		let url = (serve == 'api') ? setting.apiOctopusUrl : setting.apiBookingUrl;

		if (serve === 'app') {
			url = setting.apiBookingUrl;
		}
		const body = JSON.stringify(param);
		console.log("endpoint", url + endpoint);
	 	console.log("body", body);

     return this.http
     .post(url + endpoint, body, { headers: this._headers() })
     .pipe(
       catchError((error: HttpErrorResponse) => {
         if (error.error instanceof ErrorEvent) {
           // Manejo de errores de red o cliente
           console.error('An error occurred:', error.error.message);
         } else {
           // El backend devolvió un código de error HTTP
           console.error(`Backend returned code ${error.status}, body was: `, error.error);
         }
         // Propaga el error para que lo maneje el componente que llama
         return throwError(error);
       })
     );

	}



	// Generic Put Request
	doPut(serve: string, endpoint: string, param: any): Observable<any> {

		let url = (serve == 'api') ? setting.apiOctopusUrl : setting.apiBookingUrl;
		if (serve === 'app') {
			url = setting.apiBookingUrl;
		}
		const body = JSON.stringify(param);

		return this.http
			.put(url + endpoint, body, { headers: (this._headers()) })
			.map((response:any) => response)
			.catch(this.handleError);

	}

	// Generic Delete Request
	doDelete(serve: string, endpoint: string): Observable<any> {
		let url = (serve == 'api') ? setting.apiOctopusUrl : setting.apiBookingUrl;
		return this.http
			.delete(url + endpoint, { headers: (this._headers()) })
			.map((response:any) => response)
			.catch(this.handleError);
	}

	// Set Header options
	public _headers() {
		const headers = new HttpHeaders();
		headers.append('Accept', 'application/json');
		headers.append('Access-Control-Allow-Origin', '*');
		headers.append('Content-Type', 'application/json');
		headers.append('ApiUser', setting.ApiUser);
		headers.append('ApiKey', setting.ApiKey);
		headers.append('Authorization', 'Bearer ' + this.getCookie('auth_token'));
		return headers;
	}

	getCookie(name : string){
		var nameEQ = encodeURIComponent(name) + UsblickCommonEnum.equalChar;
		var ca = document.cookie.split(UsblickCommonEnum.dotCommaChar);
		for (var i = 0; i < ca.length; i++) {
				var c = ca[i];
				while (c.charAt(0) === UsblickCommonEnum.spaceChar)
						c = c.substring(1, c.length);
				if (c.indexOf(nameEQ) === 0)
						return decodeURIComponent(c.substring(nameEQ.length, c.length));
		}
		return null;
}

	// Handling errors in response
	private handleError(error: Response | any) {
		let errMsg: string;
		if (error instanceof Response) {
			const body:any = error.json() || '';
			const err = body.error || JSON.stringify(body);
			errMsg = `${err}`;
		} else {
			errMsg = error.message ? error.message : error.toString();
		}
		return Observable.throw(errMsg);
	}

}
